import React, { useState } from "react";
// REACT ICONS
import { MdAutorenew } from 'react-icons/md'

import { fire } from "../firebase";

import './ResetPassword.css';

function ResetPassword(props) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const {
    setReset
  } = props;

  function handleReset(ev) {
    ev.preventDefault();
    clearErrors();
    fire
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        alert("Email sent. Press OK to return to login screen.");
        setReset(false)
      })
      .catch(function (error) {
        switch (error.code) {
          case "auth/invalid-email":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setEmailError(error.message);
            break;
          default:
            break;
        }
      });
    setEmail("")
  }

  const clearErrors = () => {
    setEmailError("");
  };

  return (
    <div className="reset">
      <h2 className="titleReset">Reset Password</h2>
      <input
        type="text"
        autoFocus
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <p hidden={!emailError} className="errorMsg">{emailError}</p>
      <div className="btnContainer">
        <button onClick={() => setReset(false)} className="btnCancel secondary">Cancel</button>
        <button onClick={handleReset} className="btnReset primary"><MdAutorenew />Reset</button>
      </div>
    </div>
  );
}

export default ResetPassword;