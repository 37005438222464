import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
// HORIZONTAL SCROLLING
import ScrollContainer from 'react-indiana-drag-scroll'
// ICONS
import { MdAddCircle } from 'react-icons/md'
// CSS
import './Media.css'
// PLACEHOLDERS
import placeholder from '../../img/Placeholder_Mono.png'
// FIREBASE DATA
import { GetData } from '../../data/GetData.js'

function Media(props) {
  const history = useHistory()

  const [videos, setVideos] = useState()

  //#region FETCH
  //====================================================
  useEffect(() => {
    const states = [setVideos]
    const collections = ['videos']
    collections.forEach((collection, index) => {
      GetData(collection, states[index])
    })
  }, [])
  //====================================================
  //#endregion

  return (
    <main className='mediaContainer'>
      <div className='mediaTitle'>
        <h1 className='title'>
          Media
          <MdAddCircle
            onClick={e => history.push('/edit/media')}
            className='addIcon'
          />
        </h1>
      </div>
      <ScrollContainer className='cardContainer'>
        {/* CARDS */}
        {videos &&
          videos.map(video => {
            return (
              <NavLink to={'/edit/media/' + video.id} key={video.id} className='mediaCard'>
                <img
                  className='mediaVideo'
                  src={video.image ? video.image : placeholder}
                  alt='Logo'
                />
                <div className='mediaBody'>
                  <div className='mediaTitle'>
                    <span className='cardCaption'>Title</span>
                    <span className='cardTitle'>{video.title.en}</span>
                  </div>
                  <div className='mediaArtists'>
                    <span className='cardCaption'>URL</span>
                    <span className='cardSubTitle'>{video.url}</span>
                  </div>
                </div>
              </NavLink>
            )
          })}
      </ScrollContainer>
    </main>
  )
}

export default Media
