// FIREBASE
import { fire } from "../firebase.js";
const firestore = fire.firestore();
const storage = fire.storage();

const DeleteDataById = (collection, id, imageUrl, path, history) => {
  if (imageUrl) {
    if (imageUrl.includes("https://")) {
      storage
        .refFromURL(imageUrl)
        .delete()
        .then(() => {})
        .catch((err) => {});
    }
  }
  let doc = firestore.collection(collection).doc(id);
  doc
    .delete()
    .then(() => {
      history.replace(`/${path}`);
    })
    .catch((error) => {});
};

const DeleteFile = (collection, id) => {
  firestore
    .collection(collection)
    .get()
    .then((querySnapshot) => {
      let tempData = [];
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data());
      });
      tempData = tempData.filter((data) => data.id == id);
      tempData = tempData[0];
      if (tempData) {
        if (tempData.image) {
          let imageUrl = tempData.image;
          storage
            .refFromURL(imageUrl)
            .delete()
            .then(() => {})
            .catch((err) => {});
        }
      }
    })
    .catch((err) => {});
};

export { DeleteDataById, DeleteFile };
