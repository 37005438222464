import React from 'react'
// REACT ICONS
import { MdSave, MdDelete, MdArrowBack, MdAddCircle, MdCheck } from 'react-icons/md'
// LOADER
import Loader from "react-loader-spinner"
// Dialog
import Dialog from '../../modals/Dialog.js'

function TopNav({ history, isEditing, handleSave, handleDelete, uploadProg, update, dialogDep, setDialogDep }) {

    const DynamicSave = () => {
        switch (update) {
            case "Idle":
                return <MdSave className="editIcon" />
            case "Saving":
                return <Loader id="saveLoader" color="var(--offwhite)" type="TailSpin" height={5} width={5} />
            case "Done":
                return <MdCheck className="editIcon" />
            default:
                return <MdSave className="editIcon" />
        }
    }

    const EditingLayout = () => {
        return (
            <div className="buttonContainer editing">
                <button onClick={(e) => { setDialogDep({ ...dialogDep, edited: false }); handleSave() }} className="btnSave primary"><DynamicSave />{update === "Saving" ? "Saving" : update === "Done" ? "Done" : "Save"}</button>
                <button onClick={(e) => setDialogDep({ ...dialogDep, show: true })} className="btnDelete caution"><MdDelete className="editIcon" />Delete</button>
            </div>
        )
    }

    const CreatingLayout = () => {
        return (
            <div className="buttonContainer creating">
                <button onClick={(e) => { setDialogDep({ ...dialogDep, edited: false }); handleSave() }} className="btnSave primary"><MdAddCircle className="editIcon" />Create</button>
            </div>
        )
    }

    const UploadingLayout = () => {
        return (
            <div className="stateContainer">
                <span className="cardCaption userState">Uploading...{uploadProg}%</span>
                <progress className="progressBar" max={100} value={uploadProg} />
            </div>
        )
    }
    return (

        <div className="editNav">
            {uploadProg !== null && uploadProg < 100 ? <UploadingLayout /> : null}

            <button onClick={(e) => {
                if (dialogDep.edited) {
                    setDialogDep({ ...dialogDep, show: true, type: "noSave" })
                } else {
                    setDialogDep({ ...dialogDep, show: false, type: "" })
                    history.goBack()
                }
            }} className="btnClose secondary"><MdArrowBack className="editIcon" />Back</button>

            {isEditing ? <EditingLayout /> : <CreatingLayout />}

            <Dialog handleSave={handleSave} handleDelete={handleDelete} history={history} setDialogDep={setDialogDep} dialogDep={dialogDep} />

        </div>

    )
}

export default TopNav
