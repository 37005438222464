import React, { useState, useEffect, createRef, useRef } from "react";
import { ts } from "../firebase";
// ICONS
import { MdSave, MdFiberManualRecord, MdCheck, MdClose } from "react-icons/md";
// PLACE HOLDER
import placeholder from "../img/Placeholder_Profile_Mono.png";
// SCROLLING
import ScrollContainer from "react-indiana-drag-scroll";
// FIREBASE DATA
import { GetData } from "../data/GetData.js";
// CSS
import "./AddLineUp.css";

export const AddLineUp = ({
  setModal,
  modalStatus,
  setEventLineUp,
  eventLineUp,
  eventDate,
}) => {
  // FETCH ARTISTS FOR LINE-UP SELECTION
  const [artists, setArtists] = useState();
  // SELECTING ARTIST CARD
  const [cardID, setCardID] = useState();
  // FIELDS
  const [fields, setFields] = useState({
    lineUpTitle: "",
    lineUpTitleFR: "",
    lineUpStartTime: "09:00",
    lineUpArtist: "",
  });

  useEffect(() => {
    const states = [setArtists];
    const collections = ["acts"];
    collections.forEach((collection, index) => {
      GetData(collection, states[index]);
    });
  }, []);

  //#region REQUIRED FIELDS
  //====================================================
  // REQUIRED FIELD REFERENCES
  const lineUpTitle = useRef();
  const lineUpTitleFR = useRef();
  const lineUpStartTime = useRef();
  // REQUIRED FIELD SETUP
  let requiredRefs = [lineUpTitle, lineUpTitleFR, lineUpStartTime];
  let requiredStates = [
    fields.lineUpTitle,
    fields.lineUpTitleFR,
    fields.lineUpStartTime,
  ];
  const MINIMUM = 1;
  let isSafe = !!(
    fields.lineUpTitle &&
    fields.lineUpTitleFR &&
    fields.lineUpStartTime
  );
  // CLEAR REQUIRED FIELD CLASS
  const clearRequiredAlert = (providedRef) => {
    if (!providedRef) {
      requiredRefs.forEach((ref) => {
        ref.current.classList.remove("requiredAlert");
      });
    } else {
      providedRef.current.classList.remove("requiredAlert");
    }
  };
  // ADD REQUIRED FIELD CLASS
  const warnUser = () => {
    requiredStates.forEach((field, i) => {
      if (!field) requiredRefs[i].current.classList.add("requiredAlert");
      if (Array.isArray(field)) {
        if (field.length < MINIMUM)
          requiredRefs[i].current.classList.add("requiredAlert");
      }
    });
  };
  //====================================================
  //#endregion

  const handleSave = (e) => {
    if (isSafe) {
      // Checking if eventDate is a date or timestamp then putting it in a variable
      let tempData = {
        content: {
          en: fields.lineUpTitle,
          fr: fields.lineUpTitleFR,
        },
        act_id: fields.lineUpArtist,
        start_time: fields.lineUpStartTime,
      };
      let tempArr = eventLineUp;
      tempArr.push(tempData)
      tempArr.sort(
        (a, b) =>
          parseInt(a.start_time.replace(":", "")) -
          parseInt(b.start_time.replace(":", ""))
      );
      setEventLineUp(tempArr);
      setCardID("");
      setModal(false);
    } else {
      warnUser()
    }
  };

  return (
    <div className={modalStatus ? "modal show" : "modal"}>
      <div className="modal-content">
        <div className="lineUp-Header">
          <span className="cardTitle">Add a Line-up Item</span>
          <div className="form-buttons-container">
            <button className="btnSave primary" onClick={handleSave}>
              <MdSave className="editIcon" />
              Save
            </button>
            <button
              className="btnClose secondary"
              onClick={(e) => setModal(false)}
            >
              <MdClose className="editIcon" />
              Close
            </button>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="lineUp-fields">
            <div className="lineUp-name">
              <span className="cardTitle">Act Name (Name or Title) *</span>
              <input
                type="text"
                required
                ref={lineUpTitle}
                value={fields.lineUpTitle}
                maxLength={50}
                onChange={(e) => {
                  setFields({ ...fields, lineUpTitle: e.target.value });
                  clearRequiredAlert(lineUpTitle);
                }}
                placeholder="A clever name"
              />
            </div>
            <div className="lineUp-name-french">
              <span className="cardTitle">Act Name (French) *</span>
              <input
                type="text"
                required
                ref={lineUpTitleFR}
                value={fields.lineUpTitleFR}
                maxLength={50}
                onChange={(e) => {
                  setFields({ ...fields, lineUpTitleFR: e.target.value });
                  clearRequiredAlert(lineUpTitleFR);
                }}
                placeholder="A clever name"
              />
            </div>
            <div className="lineUp-time">
              <div className="pollStartDate">
                <span className="cardTitle">Start Time *</span>
                <input
                  type="time"
                  required
                  ref={lineUpStartTime}
                  value={fields.lineUpStartTime}
                  onChange={(e) => {
                    setFields({ ...fields, lineUpStartTime: e.target.value });
                    clearRequiredAlert(lineUpStartTime);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="lineUp-artist">
            <span className="cardTitle">Artists</span>
            <span className="cardCaption">Feature an artist</span>
            <ScrollContainer className="artist-container">
              {artists &&
                artists.map(function (artist) {
                  return (
                    <div
                      id={artist.id}
                      key={artist.id}
                      className={
                        fields.lineUpArtist === artist.id
                          ? "lineUp-artist-card selected"
                          : "lineUp-artist-card"
                      }
                      onClick={(e) => {
                        setFields({...fields, lineUpArtist: artist.id})
                        if (fields.lineUpArtist === artist.id) {
                            setFields({...fields, lineUpArtist: ""})
                        }
                      }}
                    >
                      {/* THIS WILL NEED TO CHANGE TO "IMAGE" NOT "MAIN_IMAGE" */}
                      <img
                        src={artist.image ? artist.image : placeholder}
                        alt="artists name"
                      />
                      <span className="cardTitle">{artist.name}</span>
                      {fields.lineUpArtist === artist.id ? (
                        <MdCheck className="indicatorIcon selected" />
                      ) : (
                        <MdFiberManualRecord className="indicatorIcon" />
                      )}
                    </div>
                  );
                })}
            </ScrollContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddLineUp;
