import React, { useState, createRef } from 'react'
// REACT ICONS
import { MdClose, MdAddCircle } from 'react-icons/md'
// CSS
import './AddPollChoice.css'

export const AddPollChoice = ({pollVotes, setPollVotes, setModal}) => {

    const [choiceTitle, setChoiceTitle] = useState("");

    const input = createRef();

    return (
        <div className="modal">
            <div className="modalContainer">
                <div className="modalHeader">
                    <span className="cardTitle">Add a Poll Choice</span>
                    <div className="buttonContainer">
                        <button className="btnSave primary" onClick={(e) =>{ if(pollVotes.length < 4 && choiceTitle) setPollVotes([...pollVotes, {count: 0, name: choiceTitle}]); setChoiceTitle(""); input.current.focus();}}><MdAddCircle className="editIcon" />Add</button>
                        <button className="btnClose secondary" onClick={(e) => setModal(false)}><MdClose className="editIcon" />Close</button>
                    </div>
                </div>
                <div className="modalContent">
                    <div className="pollChoiceName">
                        <span className="cardTitle">Poll Choice Title *</span>
                        <input
                            type="text"
                            ref={input}
                            value={choiceTitle}
                            maxLength={30}
                            onChange={(e) => setChoiceTitle(e.target.value)}
                            placeholder="A poll choices name..."
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPollChoice