import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
// HORIZONTAL SCROLLINGd
import ScrollContainer from 'react-indiana-drag-scroll'
// CSS
import "./Home.css"
// PLACEHOLDERS
import placeholder from '../../img/Placeholder_Mono.png'
import placeholderEats from '../../img/Placeholder_Eat_Mono.png'
// ICONS
import { MdAddCircle } from "react-icons/md"
// FIREBASE DATA
import { GetData } from '../../data/GetData.js'

function Home({ history }) {

    //#region STATES
    //====================================================
    const [feed, setFeed] = useState();
    const [polls, setPolls] = useState();
    const [eats, setEats] = useState();
    //====================================================
    //#endregion

    //#region FETCH
    //====================================================
    useEffect(() => {
        const states = [setFeed, setPolls, setEats]
        const collections = ['feed', 'polls', 'eats']
        setTimeout(() => collections.forEach((collection, index) => {
            GetData(collection, states[index]);
        }), 300)

    }, [])
    //====================================================
    //#endregion

    //#region DATE HANDLING
    //====================================================
    // REFERENCE: https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript
    const getDateDifference = (endDate) => {
        const date1 = new Date(endDate.toDate());
        const date2 = new Date(Date.now());
        if (date1 > date2) {
            const diffTime = Math.abs(date1 - date2);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        }
        return 0;
    }
    //====================================================
    //#endregion

    return (
        <main className="home-container">
            <h1 className="title">Home</h1>
            <ScrollContainer ignoreElements=".feed-card-container, .polls-card-container, .eats-card-container" className='cardContainer'>
                {/* Feed */}
                <div className="feed-container">
                    <div className="subTitle">
                        Feed
                        <MdAddCircle onClick={(e) => history.push("/edit/feed/")} className="addIcon" />
                    </div>
                    <ScrollContainer className="feed-card-container">
                        {feed && feed.map(item => {
                            if (item.type === "allText") {
                                return (
                                    <NavLink to={"edit/feed/" + item.id} className="feed-card allText" key={item.id} >
                                        <span className="cardTitle">{item.title.en}</span>
                                        <span className="cardSubTitle">{item.body.en}</span>
                                    </NavLink>
                                )
                            } else if (item.type === "image") {
                                return (
                                    <NavLink to={"edit/feed/" + item.id} className="feed-card image" key={item.id} style={{
                                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%), url(${item.image ? item.image : placeholder})`, backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                    }}>
                                        <span className="cardTitle">{item.title.en}</span>
                                        <span className="cardSubTitle">{item.body.en}</span>
                                    </NavLink>
                                )
                            } else {
                                return (
                                    <NavLink to={"edit/feed/" + item.id} className="feed-card thumbnail" key={item.id}>
                                        <div className="feed-thumbnail-box">
                                            <img src={item.image ? item.image : placeholder} alt="Logo" />
                                        </div>
                                        <div className="feed-text-box">
                                            <span className="cardTitle">{item.title.en}</span>
                                            <span className="cardSubTitle">{item.body.en}</span>
                                        </div>
                                    </NavLink>
                                )
                            }
                        })}
                    </ScrollContainer>
                </div>
                {/* Polls */}
                <div className="polls-container">
                    <div className="subTitle">
                        Polls<MdAddCircle onClick={(e) => history.push("/edit/poll/")} className="addIcon" />
                    </div>
                    <ScrollContainer className="polls-card-container">
                        {polls && polls.map(poll => {
                            return (
                                <NavLink to={"edit/poll/" + poll.id} className="poll-card" key={poll.id}>
                                    <div className="poll-info">
                                        <span className="cardTitle">{poll.title.en}</span>
                                        <span className="cardSubTitle">{poll.caption.en}</span>
                                        <span className="cardCaption">{poll.start_date.toDate().toDateString().substring(3, 15)} - {poll.end_date.toDate().toDateString().substring(3, 15)}</span>
                                    </div>
                                    <div className="poll-countdown">
                                        <span className="cardCaption">{getDateDifference(poll.end_date)} days left</span>
                                    </div>
                                    <div className="poll-col-1">
                                        {poll.votes.map((vote, index) => {
                                            return (
                                                <div key={vote.name.replace(' ', '') + index}><span className="cardSubTitle">{vote.name}</span> <span className="cardCaption">{vote.count}</span> </div>
                                            )
                                        })}
                                    </div>
                                </NavLink>
                            )
                        })}
                    </ScrollContainer>
                </div>
                {/* Eats */}
                <div className="eats-container">
                    <div className="subTitle">
                        Eats<MdAddCircle onClick={(e) => history.push("/edit/eat/")} className="addIcon" />
                    </div>
                    <ScrollContainer className="eats-card-container">
                        {eats && eats.map(eat => {
                            return (
                                <NavLink to={"edit/eat/" + eat.id} className="eats-card" key={eat.id} style={{
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%), url(${eat.image ? eat.image : placeholderEats})`, backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    borderRadius: '1rem'
                                }}>
                                    <span className="cardTitle">{eat.name}</span>
                                </NavLink>)
                        })}

                    </ScrollContainer>
                </div>
            </ScrollContainer>
        </main>
    )
}

export default Home