import React, { useState, useEffect, useRef } from 'react'
// HORIZONTAL SCROLL
import ScrollContainer from 'react-indiana-drag-scroll'
// CSS
import "./Edit.css"
import "./EditFeed.css"
// PLACEHOLDER
import Placeholder from "../../img/Placeholder_Mono.png"
// FIREBASE GET/SET/DELETE DATA
import { GetDataById } from '../../data/GetData.js'
import { SetDataUpload } from '../../data/SetData.js'
import { DeleteDataById } from '../../data/DeleteData.js'
// TOP NAV
import TopNav from './TopNav'

export const EditFeed = (props) => {

    //#region STATES
    //====================================================
    // ID
    const [dataId, setDataId] = useState(props.match.params.feedId ? props.match.params.feedId : null);
    // IF USER IS EDITING OR CREATING
    const [isEditing, setIsEditing] = useState(dataId ? true : false);
    // FETCHED DATA
    const [feedItem, setFeedItem] = useState();
    // IMAGE
    const [update, setUpdate] = useState("Idle");
    // UPLOAD PROGRESS
    const [uploadProg, setUploadProg] = useState(null);
    // FIELDS
    const [fields, setFields] = useState({
        feedTitle: "",
        feedTitleFR: "",
        feedDesc: "",
        feedDescFR: "",
        image: "",
        type: "allText"
    });
    // DIALOG PROPERTIES
    const [dialogDep, setDialogDep] = useState({
        itemName: "",
        edited: false,
        type: "",
        show: false,
    })
    //====================================================
    //#endregion

    //#region REQUIRED FIELDS
    //====================================================
    // REQUIRED FIELD REFERENCES
    const feedTitle = useRef();
    const feedTitleFR = useRef();
    // REQUIRED FIELD SETUP
    let requiredRefs = [feedTitle, feedTitleFR];
    let requiredStates = [fields.feedTitle, fields.feedTitleFR];
    const MINIMUM = 1;
    let isSafe = !!(fields.feedTitle && fields.feedTitleFR)
    // CLEAR REQUIRED FIELD CLASS
    const clearRequiredAlert = (providedRef) => {
        if (!providedRef) {
            requiredRefs.forEach((ref) => {
                ref.current.classList.remove('requiredAlert')
            })
        } else {
            providedRef.current.classList.remove('requiredAlert')
        }
    }
    // ADD REQUIRED FIELD CLASS
    const warnUser = () => {
        requiredStates.forEach((field, i) => {
            if (!field) requiredRefs[i].current.classList.add('requiredAlert')
            if (Array.isArray(field)) {
                if (field.length < MINIMUM) requiredRefs[i].current.classList.add('requiredAlert')
            }
        })
    }
    //====================================================
    //#endregion

    //#region DATA FETCH
    //====================================================
    // GETTING DATA
    useEffect(() => {
        if (dataId) {
            const states = [setFeedItem]
            const collections = ['feed']
            collections.forEach((collection, index) => {
                GetDataById(collection, states[index], dataId);
            });
            props.history.replace('/edit/feed/' + dataId)
            setIsEditing(true);
        }
    }, [update])
    // SETTING FIELDS WITH DATA
    useEffect(() => {
        if (feedItem) {
            setFields({
                feedTitle: feedItem.title.en,
                feedTitleFR: feedItem.title.fr,
                feedDesc: feedItem.body.en,
                feedDescFR: feedItem.body.fr,
                image: feedItem.image,
                type: feedItem.type
            });
            setDialogDep({ ...dialogDep, itemName: feedItem.title.en })
        }
    }, [feedItem])
    //====================================================
    //#endregion

    //#region DATA HANDLING
    //====================================================
    // HANDLING SAVE
    const handleSave = () => {
        if (isSafe) {
            let feedData = {
                id: dataId,
                title:
                {
                    en: fields.feedTitle,
                    fr: fields.feedTitleFR
                },
                body:
                {
                    en: fields.type === "allText" ? fields.feedDesc : fields.feedDesc.substring(0,100),
                    fr: fields.type === "allText" ? fields.feedDescFR : fields.feedDescFR.substring(0,100)
                },
                type: fields.image ? fields.type : "allText",
                image: fields.image
            }
            // Choosing either SetDataById() or SetData() depending if the user is editing or not
            SetDataUpload("feed", feedData, setDataId, setUploadProg, uploadProg, setUpdate);
        } else {
            warnUser()
        }
    }
    // HANDLING DELETE
    const handleDelete = () => {
        DeleteDataById("feed", dataId, feedItem.image, '', props.history)
    }
    // HANDLING UPDATE STATE
    if (update === "Done") {
        setTimeout(() => setUpdate("Idle"), 1000)
    }
    //====================================================
    //#endregion

    return (
        <main className="editFeedContainer">
            <TopNav history={props.history} isEditing={isEditing} handleSave={handleSave} handleDelete={handleDelete} uploadProg={uploadProg} update={update} dialogDep={dialogDep} setDialogDep={setDialogDep} />
            <ScrollContainer ignoreElements="input, textarea" className="editFieldContainer">
                <div className="feedTitle">
                    <span className="cardTitle">Feed Title *</span>
                    <input
                        type="text"
                        required
                        ref={feedTitle}
                        value={fields.feedTitle}
                        maxLength={35}
                        onChange={(e) => { setFields({ ...fields, feedTitle: e.target.value }); setDialogDep({ ...dialogDep, edited: true }); clearRequiredAlert(feedTitle) }}
                        placeholder="A feed title..."
                    />
                </div>
                <div className="feedTitleFR">
                    <span className="cardTitle">Feed Title (French) *</span>
                    <input
                        type="text"
                        required
                        ref={feedTitleFR}
                        value={fields.feedTitleFR}
                        maxLength={35}
                        onChange={(e) => { setFields({ ...fields, feedTitleFR: e.target.value }); setDialogDep({ ...dialogDep, edited: true }); clearRequiredAlert(feedTitleFR) }}
                        placeholder="Un nom pour le flux..."
                    />
                </div>
                <div className="feedBody">
                    <span className="cardTitle">Body</span>
                    <textarea
                        type="text"
                        value={fields.feedDesc}
                        maxLength={fields.type === "allText" ? 250 : 100}
                        onChange={(e) => { setFields({ ...fields, feedDesc: e.target.value }); setDialogDep({ ...dialogDep, edited: true }) }}
                        placeholder="A feed body..."
                    />
                </div>
                <div className="feedBodyFR">
                    <span className="cardTitle">Body (French)</span>
                    <textarea
                        type="text"
                        value={fields.feedDescFR}
                        maxLength={fields.type === "allText" ? 250 : 100}
                        onChange={(e) => { setFields({ ...fields, feedDescFR: e.target.value }); setDialogDep({ ...dialogDep, edited: true }) }}
                        placeholder="Un corps de texte pour le flux..."
                    />
                </div>
                <div className="feedImg">
                    <span className="cardTitle">Image</span>
                    <input
                        type="file"
                        onChange={(e) => { setFields({ ...fields, image: e.target.files[0] }); setDialogDep({ ...dialogDep, edited: true }) }}
                    />
                    <img className="feedImagePreview preview" alt="feed" src={fields.image ? (fields.image.name ? URL.createObjectURL(fields.image) : fields.image) : Placeholder} />
                </div>

                <div className="feedTypes">
                    <span className="cardTitle">Feed Type</span>
                    <span className="cardCaption">Select one</span>
                    <div className="typesContainer">
                        <div className={"typeContainer" + (fields.type === "allText" ? " checked" : "")} onClick={(e) => { setFields({ ...fields, type: "allText" }); setDialogDep({ ...dialogDep, edited: true }) }}>
                            <span className="cardTitle">Text Feed</span>
                            <span className="cardCaption">Displays a title and a caption on a plain background.</span>
                        </div>
                        <div className={"typeContainer" + (fields.type === "thumbnail" ? " checked" : "")} onClick={(e) => { setFields({ ...fields, type: "thumbnail" }); setDialogDep({ ...dialogDep, edited: true }) }}>
                            <span className="cardTitle">Thumbnail Feed</span>
                            <span className="cardCaption">Displays a title and a caption with a plain background next to an image.</span>
                        </div>
                        <div className={"typeContainer" + (fields.type === "image" ? " checked" : "")} onClick={(e) => { setFields({ ...fields, type: "image" }); setDialogDep({ ...dialogDep, edited: true }) }}>
                            <span className="cardTitle">Image Feed</span>
                            <span className="cardCaption">Displays a title and a caption on an image background.</span>
                        </div>
                    </div>
                </div>
            </ScrollContainer>
        </main>
    )
}

export default EditFeed