import React, { useState, useEffect, useRef } from "react";
// CSS
import "./Edit.css";
import "./EditEat.css";
// SCROLL
import ScrollContainer from "react-indiana-drag-scroll";
// FIREBASE GET/SET/DELETE DATA
import { GetDataById } from "../../data/GetData.js";
import { SetDataUpload } from "../../data/SetData.js";
import { DeleteDataById } from "../../data/DeleteData.js";
// TOP NAV
import TopNav from "./TopNav";
// PLACEHOLDER
import placeholder from "../../img/Placeholder_Eat_Mono.png";

export const EditEats = (props) => {
  //#region STATES
  //====================================================
  // ID
  const [dataId, setDataId] = useState(
    props.match.params.eatId ? props.match.params.eatId : null
  );
  // IF USER IS EDITING OR CREATING
  const [isEditing, setIsEditing] = useState(dataId ? true : false);
  // FETCHED DATA
  const [eatData, setEatData] = useState();
  // UPDATE STATE
  const [update, setUpdate] = useState("Idle");
  // UPLOAD PROGRESS
  const [uploadProg, setUploadProg] = useState(null);
  // FIELDS
  const [fields, setFields] = useState({
    name: "",
    image: "",
    about: "",
    aboutFR: "",
    address: "",
    link: "",
  });
  // DIALOG PROPERTIES
  const [dialogDep, setDialogDep] = useState({
    itemName: "",
    edited: false,
    type: "",
    show: false,
  });
  //====================================================
  //#endregion

  //#region REQUIRED FIELDS
  //====================================================
  // REQUIRED FIELD REFERENCES
  const eatName = useRef();
  const eatImage = useRef();
  // REQUIRED FIELD SETUP
  let requiredRefs = [eatName, eatImage];
  let requiredStates = [fields.name, fields.image];
  const MINIMUM = 1;
  let isSafe = !!(fields.name && fields.image);
  // CLEAR REQUIRED FIELD CLASS
  const clearRequiredAlert = (providedRef) => {
    if (!providedRef) {
      requiredRefs.forEach((ref) => {
        ref.current.classList.remove("requiredAlert");
      });
    } else {
      providedRef.current.classList.remove("requiredAlert");
    }
  };
  // ADD REQUIRED FIELD CLASS
  const warnUser = () => {
    requiredStates.forEach((field, i) => {
      if (!field) requiredRefs[i].current.classList.add("requiredAlert");
      if (Array.isArray(field)) {
        if (field.length < MINIMUM)
          requiredRefs[i].current.classList.add("requiredAlert");
      }
    });
  };
  //====================================================
  //#endregion

  //#region DATA FETCH
  //====================================================
  // GETTING DATA
  useEffect(() => {
    if (dataId) {
      const states = [setEatData];
      const collections = ["eats"];
      collections.forEach((collection, index) => {
        GetDataById(collection, states[index], dataId);
      });
      props.history.replace("/edit/eat/" + dataId);
      setIsEditing(true);
    }
  }, [update]);
  // SETTING DATA
  useEffect(() => {
    if (eatData) {
      setFields({
        name: eatData.name,
        image: eatData.image,
        about: eatData.about.en,
        aboutFR: eatData.about.fr,
        address: eatData.address,
        link: eatData.link,
      });
      setDialogDep({ ...dialogDep, itemName: eatData.name });
    }
  }, [eatData]);
  //====================================================
  //#endregion

  //#region HANDLING DATA
  //====================================================
  // HANDLING SAVE FUNCTION
  const handleSave = () => {
    if (isSafe) {
      let tempData = {
        id: dataId,
        name: fields.name,
        about: {
          en: fields.about,
          fr: fields.aboutFR,
        },
        image: fields.image,
        address: fields.address,
        link: handleURL(fields.link) ? fields.link : "",
      };
      // Choosing either SetDataById() or SetData() depending if the user is editing or not
      SetDataUpload("eats", tempData, setDataId, setUploadProg, uploadProg, setUpdate);
    } else {
      warnUser();
    }
  };
  // HANDLING DELETE
  const handleDelete = () => {
    DeleteDataById("eats", dataId, eatData.image, "", props.history);
  };
  // HANDLING URL VALIDATION
  const handleURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };
  // HANDLING UPDATE STATE
  if (update === "Done") {
    setTimeout(() => setUpdate("Idle"), 1000);
  }
  //====================================================
  //#endregion

  return (
    <main className="editEatsContainer">
      <TopNav
        history={props.history}
        isEditing={isEditing}
        handleSave={handleSave}
        handleDelete={handleDelete}
        uploadProg={uploadProg}
        update={update}
        dialogDep={dialogDep}
        setDialogDep={setDialogDep}
      />
      <ScrollContainer
        ignoreElements="input, textarea"
        className="editFieldContainer"
      >
        <div className="eatsImage">
          <span className="cardTitle">Eat Image *</span>
          <input
            type="file"
            required
            ref={eatImage}
            onChange={(e) => {
              setFields({ ...fields, image: e.target.files[0] });
              setDialogDep({ ...dialogDep, edited: true });
              clearRequiredAlert(eatImage);
            }}
          />
        </div>
        <img
          className="eatsImagePreview preview"
          alt="restaurant, food"
          src={
            fields.image
              ? fields.image.name
                ? URL.createObjectURL(fields.image)
                : fields.image
              : placeholder
          }
        />
        <div className="eatsTitle">
          <span className="cardTitle">Name *</span>
          <input
            type="text"
            required
            ref={eatName}
            value={fields.name}
            maxLength={50}
            onChange={(e) => {
              setFields({ ...fields, name: e.target.value });
              setDialogDep({ ...dialogDep, edited: true });
              clearRequiredAlert(eatName);
            }}
            placeholder="An eatery's name..."
          />
        </div>
        <div className="eatsAddress">
          <span className="cardTitle">Address</span>
          <input
            type="text"
            value={fields.address}
            maxLength={50}
            onChange={(e) => {
              setFields({ ...fields, address: e.target.value });
              setDialogDep({ ...dialogDep, edited: true });
            }}
            placeholder="An eatery's address..."
          />
        </div>
        <div className="eatsLink">
          <span className="cardTitle">Link</span>
          <input
            type="url"
            value={fields.link}
            onChange={(e) => {
              setFields({ ...fields, link: e.target.value });
              setDialogDep({ ...dialogDep, edited: true });
            }}
            placeholder="www.restaurant.com..."
          />
        </div>
        <div className="eatsDescContainer">
          <div className="eats-description-en">
            <span className="cardTitle">Description</span>
            <textarea
              type="text"
              value={fields.about}
              maxLength={250}
              onChange={(e) => {
                setFields({ ...fields, about: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="An eatery's description..."
            />
          </div>
          <div className="eats-description-fr">
            <span className="cardTitle">Description (French)</span>
            <textarea
              type="text"
              value={fields.aboutFR}
              maxLength={250}
              onChange={(e) => {
                setFields({ ...fields, aboutFR: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="Une description pour un restaurant..."
            />
          </div>
        </div>
      </ScrollContainer>
    </main>
  );
};

export default EditEats;
