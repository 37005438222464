import React from 'react'
// REACT ROUTER
import { NavLink, Route, useHistory } from 'react-router-dom'
// REACT ICONS
import {
    MdHome,
    MdPermMedia,
    MdEvent,
    MdPeople,
    MdMood,
    MdDirectionsRun
} from 'react-icons/md'
// MAIN SCREENS
import Home from './home/Home.js'
import Events from './events/Events.js'
import Artists from './artists/Artists.js'
import Media from './media/Media.js'
import Volunteer from './volunteer/Volunteer.js'
// EDIT SCREENS
import EditPolls from "./edit/EditPolls.js"
import EditFeed from "./edit/EditFeed.js"
import EditEats from "./edit/EditEat.js"
import EditEvent from "./edit/EditEvent.js"
import EditFestival from "./edit/EditFestival.js"
import EditAccounts from "./edit/EditAccount.js"
import EditTask from "./edit/EditTask.js"
import EditMedia from "./edit/EditMedia.js"
import EditArtists from "./edit/EditArtist.js"
// OGF LOGO
import OGFLogo from '../img/OGF_Logo_White.png'
// CSS
import './Dashboard.css'

function Dashboard({ handleLogout }) {

    const history = useHistory();

    return (
        <div className="dashContainer" >
            {/* MAIN SIDE-NAVIGATION */}
            <nav>
                <div className="titleContainer">
                    <img src={OGFLogo} alt="Ottawa Grassroots Festival Logo" className="imgTitle" />
                    <h1>Admin Dashboard</h1>
                </div>
                <div className="linkContainer">
                    <NavLink className="navItem" isActive={(match, location) => { if (location.pathname === "/" || location.pathname.includes('/poll') || location.pathname.includes('/feed') || location.pathname.includes('/eat')) return true; }} exact to="/"><MdHome className="navIcon" />Home</NavLink>
                    <NavLink className="navItem" isActive={(match, location) => { if (location.pathname.includes('/events') || location.pathname.includes('/event') || location.pathname.includes('/festival')) return true; }} to="/events"><MdEvent className="navIcon" />Events</NavLink>
                    <NavLink className="navItem" isActive={(match, location) => { if (location.pathname.includes('/artists') || location.pathname.includes('/artist')) return true; }} to="/artists"><MdPeople className="navIcon" />Artists</NavLink>
                    <NavLink className="navItem" isActive={(match, location) => { if (location.pathname.includes('/media')) return true; }} to="/media"><MdPermMedia className="navIcon" />Media</NavLink>
                    <NavLink className="navItem" isActive={(match, location) => { if (location.pathname.includes('/accounts') || location.pathname.includes('/account') || location.pathname.includes('/task') || location.pathname.includes('/tasks')) return true; }} to="/accounts"><MdMood className="navIcon" />Volunteer</NavLink>
                </div>
                <button className="btnLogout" onClick={(e) => { history.push('/'); handleLogout(); }}><MdDirectionsRun style={{ transform: 'scaleX(-1)' }} />Logout</button>
            </nav>

            {/* DASHBOARD ROUTES, USED TO VIEW CARDS */}

            {/* HOME ROUTE, CONTAINS: UPCOMING EVENTS, FEED, POLLS, EATS */}
            <Route exact path="/" component={Home} />
            {/* EVENTS ROUTE, CONTAINS: GENRAL EVENTS, FESTIVALS */}
            <Route exact path="/events" component={Events} />
            {/* ARTSITS ROUTE, CONTAINS: ARTISTS */}
            <Route exact path="/artists" component={Artists} />
            {/* MEDIA ROUTE, CONTAINS: VIDEOS */}
            <Route exact path="/media" component={Media} />
            {/* VOLUNTEER ROUTE, CONTAINS: ACCOUNTS COMPONENT, TASKS COMPONENT, ACCOUNTS, TASKS */}
            <Route path={["/tasks", "/accounts"]} component={Volunteer} />

            {/* EDIT/CREATE ROUTES, USED TO EDIT SELECTED ITEMS */}
            {/* EDIT FEED */}
            <Route path={["/edit/feed/:feedId", "/edit/feed/"]} component={EditFeed} />
            {/* EDIT POLL */}
            <Route path={["/edit/poll/:pollId", "/edit/poll/"]} component={EditPolls} />
            {/* EDIT EAT */}
            <Route path={["/edit/eat/:eatId", "/edit/eat/"]} component={EditEats} />
            {/* EDIT EVENT */}
            <Route path={["/edit/event/:eventId", "/edit/event/", "/edit/festivalEvent/:festivalId"]} component={EditEvent} />
            {/* EDIT FESTIVALS */}
            <Route path={["/edit/festival/:festivalId", "/edit/festival/"]} component={EditFestival} />
            {/* EDIT ARTISTS */}
            <Route path={["/edit/artist/:artistId", "/edit/artist/"]} component={EditArtists} />
            {/* EDIT MEDIA */}
            <Route path={["/edit/media/:mediaId", "/edit/media/"]} component={EditMedia} />
            {/* EDIT ACCOUNT */}
            <Route path={["/edit/account/:accountId", "/edit/account/"]} component={EditAccounts} />
            {/* EDIT TASK */}
            <Route path={["/edit/task/:taskId", "/edit/task/"]} component={EditTask} />
    </div>
  )
}

export default Dashboard
