import React, { useEffect, useState, useRef } from "react";
// HORIZONTAL SCROLL
import ScrollContainer from "react-indiana-drag-scroll";
//CSS
import "./Edit.css";
import "./EditMedia.css";
// PLACEHOLDER
import placeholder from "../../img/Placeholder_Mono.png";
// FIREBASE GET/SET/DELETE DATA
import { GetDataById } from "../../data/GetData.js";
import { SetDataUpload } from "../../data/SetData.js";
import { DeleteDataById } from "../../data/DeleteData.js";
// TOP NAV
import TopNav from "./TopNav";

export const EditMedia = (props) => {
  //#region STATES
  //====================================================
  // ID
  const [dataId, setDataId] = useState(
    props.match.params.mediaId ? props.match.params.mediaId : null
  );
  // IF USER IS EDITING OR CREATING
  const [isEditing, setIsEditing] = useState(dataId ? true : false);
  // FETCHED DATA
  const [mediaData, setMediaData] = useState();
  // UPDATE STATE
  const [update, setUpdate] = useState("Idle");
  // UPLOAD PROGRESS
  const [uploadProg, setUploadProg] = useState(null);
  // FIELDS
  const [fields, setFields] = useState({
    mediaTitle: "",
    mediaTitleFR: "",
    mediaUrl: "",
    image: "",
  });
  // DIALOG PROPERTIES
  const [dialogDep, setDialogDep] = useState({
    itemName: fields.eventTitle,
    edited: false,
    type: "",
    show: false,
  });
  //====================================================
  //#endregion

  //#region REQUIRED FIELDS
  //====================================================
  // REQUIRED FIELD REFERENCES
  const videoTitle = useRef();
  const videoTitleFR = useRef();
  const videoURL = useRef();
  // REQUIRED FIELD SETUP
  let requiredRefs = [videoTitle, videoTitleFR, videoURL];
  const MINIMUM = 0;
  let requiredStates = [
    fields.mediaTitle,
    fields.mediaTitleFR,
    fields.mediaUrl,
  ];
  let isSafe = !!(fields.mediaTitle && fields.mediaTitleFR && fields.mediaUrl);
  // CLEAR REQUIRED FIELD CLASS
  const clearRequiredAlert = (providedRef) => {
    if (!providedRef) {
      requiredRefs.forEach((ref) => {
        ref.current.classList.remove("requiredAlert");
      });
    } else {
      providedRef.current.classList.remove("requiredAlert");
    }
  };
  // ADD REQUIRED FIELD CLASS
  const warnUser = () => {
    requiredStates.forEach((field, i) => {
      if (!field) requiredRefs[i].current.classList.add("requiredAlert");
      if (Array.isArray(field)) {
        if (field.length < MINIMUM)
          requiredRefs[i].current.classList.add("requiredAlert");
      }
    });
  };
  //====================================================
  //#endregion

  //#region DATA FETCH
  //====================================================
  // GETTING DATA
  useEffect(() => {
    if (dataId) {
      const states = [setMediaData];
      const collections = ["videos"];
      collections.forEach((collection, index) => {
        GetDataById(collection, states[index], dataId);
      });
      props.history.replace("/edit/media/" + dataId);
      setIsEditing(true);
    }
  }, [update]);
  // SETTING DATA
  useEffect(() => {
    if (mediaData) {
      setFields({
        mediaTitle: mediaData.title.en,
        mediaTitleFR: mediaData.title.fr,
        mediaUrl: mediaData.url,
        image: mediaData.image,
      });
      setDialogDep({ ...dialogDep, itemName: mediaData.title.en });
    }
  }, [mediaData]);
  //====================================================
  //#endregion

  //#region DATA HANDLING
  //====================================================
  // HANDLE SAVE
  const handleSave = () => {
    if (isSafe) {
      let tempData = {
        id: dataId,
        title: {
          en: fields.mediaTitle,
          fr: fields.mediaTitleFR,
        },
        url: handleURL(fields.mediaUrl) ? fields.mediaUrl : "",
        image: fields.image,
      };
      // Choosing either SetDataById() or SetData() depending if the user is editing or not
      SetDataUpload("videos", tempData, setDataId, setUploadProg, uploadProg, setUpdate);
    } else {
      warnUser();
    }
  };
  // HANDLE DELETE
  const handleDelete = () => {
    DeleteDataById("videos", dataId, mediaData.image, "media", props.history);
  };
  // HANDLING URL VALIDATION
  const handleURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };
  // HANDLING UPDATE STATE
  if (update === "Done") {
    setTimeout(() => setUpdate("Idle"), 1000);
  }
  //====================================================
  //#endregion

  return (
    <main className="editMediaContainer">
      <TopNav
        history={props.history}
        isEditing={isEditing}
        handleSave={handleSave}
        handleDelete={handleDelete}
        uploadProg={uploadProg}
        update={update}
        dialogDep={dialogDep}
        setDialogDep={setDialogDep}
      />
      <ScrollContainer
        ignoreElements="input, textarea"
        className="editFieldContainer"
      >
        <div className="mediaTitleEN">
          <span className="cardTitle">Video Title *</span>
          <input
            type="text"
            required
            ref={videoTitle}
            value={fields.mediaTitle}
            maxLength={50}
            onChange={(e) => {
              setFields({ ...fields, mediaTitle: e.target.value });
              setDialogDep({ ...dialogDep, edited: true });
              clearRequiredAlert(videoTitle);
            }}
            placeholder="A video title..."
          />
        </div>
        <div className="mediaTitleFR">
          <span className="cardTitle">Video Title (French) *</span>
          <input
            type="text"
            required
            ref={videoTitleFR}
            value={fields.mediaTitleFR}
            maxLength={50}
            onChange={(e) => {
              setFields({ ...fields, mediaTitleFR: e.target.value });
              setDialogDep({ ...dialogDep, edited: true });
              clearRequiredAlert(videoTitleFR);
            }}
            placeholder="Un titre pour le video..."
          />
        </div>
        <div className="mediaUrl">
          <span className="cardTitle">Video Youtube URL *</span>
          <input
            type="text"
            required
            ref={videoURL}
            value={fields.mediaUrl}
            onChange={(e) => {
              setFields({ ...fields, mediaUrl: e.target.value });
              setDialogDep({ ...dialogDep, edited: true });
              clearRequiredAlert(videoURL);
            }}
            placeholder="www.youtube.com/something...."
          />
        </div>
        <div className="mediaImage">
          <span className="cardTitle">Thumbnail Image</span>
          <input
            type="file"
            onChange={(e) => {
              setFields({ ...fields, image: e.target.files[0] });
              setDialogDep({ ...dialogDep, edited: true });
            }}
          />
        </div>
        <img
          className="videoImagePreview preview"
          alt="video thumbnail"
          src={
            fields.image
              ? fields.image.name
                ? URL.createObjectURL(fields.image)
                : fields.image
              : placeholder
          }
        />
      </ScrollContainer>
    </main>
  );
};

export default EditMedia;
