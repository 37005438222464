import React, { useState, useEffect, useRef } from "react";
import { ts } from "../firebase";
// HORIZONTAL SCROLL
import ScrollContainer from "react-indiana-drag-scroll";
// DIALOG STYLING
import "./Dialog.css";
import "../screens/edit/Edit.css";
import "../screens/edit/EditEvent.css";
import "./AddFestivalEvent.css";
// ICONS
import { MdClose, MdAddCircle, MdSave, MdDelete } from "react-icons/md";
// PLACEHOLDER
import Placeholder from "../img/Placeholder_Mono.png";
// REACT SELECT DROPDOWN
import Select from "react-select";
// FIREBASE DATA
import { GetData } from "../data/GetData.js";
import { GetInputDateFormat } from "../data/GetInputDateFormat.js";

function AddFestivalEvent({
  setModal,
  modalStatus,
  setFestivalEvents,
  festivalEvents,
  setSelectedEvent,
  selectedEvent,
}) {
  //#region STATES
  //====================================================
  // FEILDS
  const [fields, setFields] = useState({
    eventTitle: "",
    eventTitleFR: "",
    eventDesc: "",
    eventDescFR: "",
    date: new Date(),
    location: "",
    cost: "",
    eventbriteLink: "",
    image: "",
  });
  const [lineUpList, setLineUpList] = useState([]);

  const [lineUpEditing, setLineUpEditing] = useState(null);
  const [addLineUp, setAddLineUp] = useState(false);
  //====================================================
  //#endregion
  useEffect(() => {
    if (selectedEvent) {
      setFields({
        eventTitle: selectedEvent.title.en,
        eventTitleFR: selectedEvent.title.fr,
        eventDesc: selectedEvent.description.en,
        eventDescFR: selectedEvent.description.fr,
        date: selectedEvent.date,
        location: selectedEvent.location,
        cost: selectedEvent.cost,
        eventbriteLink: selectedEvent.eventbrite_link,
        image: selectedEvent.image,
      });
      setLineUpList(selectedEvent.line_up);
    } else {
      setSelectedEvent(null);
    }
  }, [selectedEvent]);
  //#region REQUIRED FIELDS
  //====================================================
  // REQUIRED FIELD REFERENCES
  const eventTitle = useRef();
  const eventTitleFR = useRef();
  // REQUIRED FIELD SETUP
  let requiredRefs = [eventTitle, eventTitleFR];
  let requiredStates = [fields.eventTitle, fields.eventTitleFR];
  const MINIMUM = 1;
  let isSafe = !!(fields.eventTitle && fields.eventTitleFR);
  // CLEAR REQUIRED FIELD CLASS
  const clearRequiredAlert = (providedRef) => {
    if (!providedRef) {
      requiredRefs.forEach((ref) => {
        ref.current.classList.remove("requiredAlert");
      });
    } else {
      providedRef.current.classList.remove("requiredAlert");
    }
  };
  // ADD REQUIRED FIELD CLASS
  const warnUser = () => {
    requiredStates.forEach((field, i) => {
      if (!field) requiredRefs[i].current.classList.add("requiredAlert");
      if (Array.isArray(field)) {
        if (field.length < MINIMUM)
          requiredRefs[i].current.classList.add("requiredAlert");
      }
    });
  };
  //====================================================
  //#endregion

  //#region DATA HANDLING
  //====================================================
  // HANDLING SAVE
  const handleEventSave = (e) => {
    if (isSafe) {
      let tempData = {
        id: selectedEvent ? selectedEvent.id : Date.now(),
        title: {
          en: fields.eventTitle,
          fr: fields.eventTitleFR,
        },
        description: {
          en: fields.eventDesc,
          fr: fields.eventDescFR,
        },
        date: fields.date.seconds ? fields.date : ts.fromDate(fields.date),
        location: fields.location,
        cost: fields.cost,
        eventbrite_link: fields.eventbriteLink,
        image: fields.image,
        line_up: lineUpList,
      };
      let tempArr = festivalEvents;
      if (selectedEvent) {
        tempArr = tempArr.map((event) => {
          if (event.id === selectedEvent.id) {
            event = tempData;
          }
          return event;
        });
      } else {
        tempArr.push(tempData);
      }
      setFestivalEvents(tempArr);
      setSelectedEvent(null);
      setModal(false);
    } else {
      warnUser();
    }
  };
  // HANDLING DELETE
  const handleEventDelete = (e) => {
    if (selectedEvent) {
      let tempArr = festivalEvents;
      tempArr = tempArr.filter((event) => {
        return event.id !== selectedEvent.id;
      });
      setFestivalEvents(tempArr);
    }
    setSelectedEvent(null);
    setModal(false);
  };
  //====================================================
  //#endregion

  //#region DROPDOWN STYLES
  //====================================================
  const dropDownStyles = {
    option: (styles, state) => ({
      borderBottom: "1px solid hsl(140, 45%, 54%)",
      backgroundColor: state.isSelected
        ? "hsl(140, 45%, 54%)"
        : state.isFocused
        ? "hsla(140, 45%, 54%, 0.2)"
        : "hsla(0, 0%, 98%)",
      color: state.isSelected ? "white" : "hsl(140, 45%, 54%)",
      padding: "1rem",
      cursor: "pointer",
      ":first-of-type": {
        borderRadius: "1rem 1rem 0 0",
      },
      ":last-child": {
        borderRadius: "0 0 1rem 1rem",
        borderBottom: "none",
      },
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "hsla(0, 0%, 94%)",
      color: "hsl(140, 45%, 54%)",
      borderRadius: "1rem",
      padding: "0.1rem 0.2rem",
      border: "2px solid transparent",
      transition: "200ms",
      ":hover": {
        borderColor: "hsl(140, 45%, 54%)",
        cursor: "pointer",
        backgroundColor: "hsla(0, 0%, 98%)",
      },
    }),
    input: (styles) => ({
      ...styles,
      color: "hsl(140, 45%, 54%)",
      borderRadius: "1rem",
      padding: "0.3rem 0.3rem",
      border: "2px solid transparent",
      transition: "200ms",
      ":hover": {
        borderColor: "hsl(140, 45%, 54%)",
        cursor: "pointer",
        backgroundColor: "hsla(0, 0%, 98%)",
      },
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: data ? "hsl(140, 45%, 54%)" : "hsla(0, 0%, 98%)",
    }),
  };
  //====================================================
  //#endregion

  const AddLineUpItem = () => {
    //#region STATES
    const [artists, setArtists] = useState();
    const [artistList, setArtistList] = useState([
      {
        value: "0",
        label: "...",
      },
    ]);
    // SELECTED DROP DOWN ITEM
    const [selectedOption, setSelectedOption] = useState(null);
    // LINEUP FIELDS
    const [fieldsLineUp, setFieldsLineUp] = useState({
      lineUpTitle: "",
      lineUpTitleFR: "",
      lineUpStartTime: "09:00",
      lineUpArtist: "",
    });
    //#endregion
    //====================================================

    //#region ARTISTS FETCH & SET
    //====================================================
    useEffect(() => {
      const states = [setArtists];
      const collections = ["acts"];
      collections.forEach((collection, index) => {
        GetData(collection, states[index]);
      });
    }, []);

    useEffect(() => {
      if (artists) {
        const filteredArtists = artists.map(function (artist) {
          return {
            value: artist.id,
            label: artist.name,
          };
        });
        setArtistList(filteredArtists);
      }
    }, [artists]);

    useEffect(() => {
      if (lineUpEditing) {
        let chosenArtist = artistList.filter((listItem) => {
          return listItem.value === lineUpEditing.act_id;
        });
        setSelectedOption(chosenArtist[0]);
      } else {
        setSelectedOption(null);
      }
    }, [artistList]);
    //====================================================
    //#endregion

    //#region REQUIRED FIELDS
    //====================================================
    // REQUIRED FIELD REFERENCES
    const lineUpTitle = useRef();
    const lineUpTitleFR = useRef();
    const lineUpStartTime = useRef();
    // REQUIRED FIELD SETUP
    let requiredLineUpRefs = [lineUpTitle, lineUpTitleFR, lineUpStartTime];
    let requiredLineUpStates = [
      fieldsLineUp.lineUpTitle,
      fieldsLineUp.lineUpTitleFR,
      fieldsLineUp.lineUpStartTime,
    ];
    const MINIMUM = 1;
    let lineUpIsSafe = !!(
      fieldsLineUp.lineUpTitle &&
      fieldsLineUp.lineUpTitleFR &&
      fieldsLineUp.lineUpStartTime
    );
    // CLEAR REQUIRED FIELD CLASS
    const clearRequiredAlert = (providedRef) => {
      if (!providedRef) {
        requiredLineUpRefs.forEach((ref) => {
          ref.current.classList.remove("requiredAlert");
        });
      } else {
        providedRef.current.classList.remove("requiredAlert");
      }
    };
    // ADD REQUIRED FIELD CLASS
    const warnUser = () => {
      requiredLineUpStates.forEach((field, i) => {
        if (!field)
          requiredLineUpRefs[i].current.classList.add("requiredAlert");
        if (Array.isArray(field)) {
          if (field.length < MINIMUM)
            requiredLineUpRefs[i].current.classList.add("requiredAlert");
        }
      });
    };
    //====================================================
    //#endregion

    // IF EXISTING LINEUP
    useEffect(() => {
      if (lineUpEditing) {
        setFieldsLineUp({
          lineUpTitle: lineUpEditing.content.en,
          lineUpTitleFR: lineUpEditing.content.fr,
          lineUpStartTime: lineUpEditing.start_time,
          lineUpArtist: lineUpEditing.act_id,
        });
      } else {
        setLineUpEditing(null);
      }
    }, [lineUpEditing]);

    //#region DATA HANDLING
    //====================================================
    const handleLineUpSave = (e) => {
      if (lineUpIsSafe) {
        let tempLineUp = {
          content: {
            en: fieldsLineUp.lineUpTitle,
            fr: fieldsLineUp.lineUpTitleFR,
          },
          start_time: fieldsLineUp.lineUpStartTime,
          act_id: selectedOption ? selectedOption.value : "",
        };
        let tempArr = lineUpList;
        if (lineUpEditing) {
          tempArr.splice(lineUpEditing.index, 1, tempLineUp);
          setLineUpEditing(null);
        } else {
          tempArr.push(tempLineUp);
        }
        tempArr.sort(
          (a, b) =>
            parseInt(a.start_time.replace(":", "")) -
            parseInt(b.start_time.replace(":", ""))
        );
        setLineUpList(tempArr);
        setAddLineUp(false);
      } else {
        warnUser();
      }
    };

    const handleLineUpDelete = (e) => {
      let tempArr = lineUpList;
      tempArr.splice(lineUpEditing.index, 1);
      setLineUpList(tempArr);
      setLineUpEditing(null);
      setAddLineUp(false);
    };
    //====================================================
    //#endregion

    return (
      <ScrollContainer
        ignoreElements="input, textarea"
        className="lineUpContainer"
      >
        <div className="addForm-card">
          <div className="addForm-wrapper">
            <div className="addForm-title">
              <span className="cardTitle">Act Name (Name or Title) *</span>
              <input
                type="text"
                value={fieldsLineUp.lineUpTitle}
                ref={lineUpTitle}
                required
                onChange={(e) => {
                  setFieldsLineUp({
                    ...fieldsLineUp,
                    lineUpTitle: e.target.value,
                  });
                  clearRequiredAlert(lineUpTitle);
                }}
              />
            </div>
            <div className="addForm-titleFr">
              <span className="cardTitle">Act Name (French) *</span>
              <input
                type="text"
                value={fieldsLineUp.lineUpTitleFR}
                ref={lineUpTitleFR}
                required
                onChange={(e) => {
                  setFieldsLineUp({
                    ...fieldsLineUp,
                    lineUpTitleFR: e.target.value,
                  });
                  clearRequiredAlert(lineUpTitleFR);
                }}
              />
            </div>
            <div className="addForm-time">
              <span className="cardTitle">Start Time *</span>
              <input
                type="time"
                value={fieldsLineUp.lineUpStartTime}
                ref={lineUpStartTime}
                required
                onChange={(e) => {
                  setFieldsLineUp({
                    ...fieldsLineUp,
                    lineUpStartTime: e.target.value.toString(),
                  });
                  clearRequiredAlert(lineUpStartTime);
                }}
              />
            </div>
            <div className="addForm-artist">
              <span className="cardTitle">Artists</span>
              <Select
                className="artist-dropdown"
                placeholder="Select an Artist"
                isClearable={true}
                backspaceRemovesValue={true}
                menuPlacement="top"
                menuPosition={"fixed"}
                value={selectedOption}
                options={artistList}
                closeMenuOnSelect="true"
                onChange={(e) => {
                  setSelectedOption(e);
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 15,
                  colors: {
                    ...theme.colors,
                    primary25: "hsl(139, 50%, 45%)",
                    primary: "hsl(139, 50%, 45%)",
                  },
                })}
                styles={dropDownStyles}
              />
            </div>
            <div className="form-buttons-container">
              <button className="btnSave primary" onClick={handleLineUpSave}>
                <MdSave className="editIcon" />
                Save
              </button>
              {lineUpEditing ? (
                <button
                  className="btnDelete caution"
                  onClick={handleLineUpDelete}
                >
                  <MdDelete className="editIcon" />
                  Delete
                </button>
              ) : null}
              <button
                className="btnClose secondary"
                onClick={(e) => setAddLineUp(false)}
              >
                <MdClose className="editIcon" />
                Close
              </button>
            </div>
          </div>
        </div>
      </ScrollContainer>
    );
  };

  const LineUpList = () => {
    return (
      <ScrollContainer className="lineUpContainer">
        {lineUpList &&
          lineUpList.map(function (item, index) {
            return (
              <div
                className="lineUpCard"
                key={index}
                onClick={(e) => {
                  setAddLineUp(true);
                  setLineUpEditing({ ...item, index: index });
                }}
              >
                <div className="lineUpTime">
                  <span className="cardCaption">{item.start_time}</span>
                </div>
                <div className="lineUpBody">
                  <span className="cardTitle">{item.content.en}</span>
                </div>
              </div>
            );
          })}
        <div className="spacer"></div>
      </ScrollContainer>
    );
  };
  return (
    <div className={modalStatus ? "dialog show" : "dialog"}>
      <ScrollContainer
        ignoreElements=".lineUpContainer"
        className="eventModal-content"
      >
        {/* BUTTONS */}
        <div className="eventModal-Header">
          <span className="cardTitle">Add an Event</span>
          <div className="form-buttons-container">
            <button className="btnSave primary" onClick={handleEventSave}>
              <MdSave className="editIcon" />
              Save
            </button>
            {selectedEvent ? (
              <button className="btnDelete caution" onClick={handleEventDelete}>
                <MdDelete className="editIcon" />
                Delete
              </button>
            ) : null}
            <button
              className="btnClose secondary"
              onClick={(e) => {
                setSelectedEvent(null);
                setModal(false);
              }}
            >
              <MdClose className="editIcon" />
              Close
            </button>
          </div>
        </div>

        {/* IMAGE */}
        <div className="eventImage">
          <span className="cardTitle">Event Image</span>
          <input
            type="file"
            required
            onChange={(e) => {
              setFields({ ...fields, image: e.target.files[0] });
            }}
          />
        </div>
        <img
          className="eventModalImagePreview"
          alt="event, workshop, concert"
          src={
            fields.image
              ? fields.image.name
                ? URL.createObjectURL(fields.image)
                : fields.image
              : Placeholder
          }
        />

        {/* TITLES */}
        <div className="eventTitles">
          <div className="eventTitle">
            <span className="cardTitle">Event Title *</span>
            <input
              type="text"
              ref={eventTitle}
              required
              maxLength={50}
              value={fields.eventTitle}
              onChange={(e) => {
                setFields({ ...fields, eventTitle: e.target.value });
                clearRequiredAlert(eventTitle);
              }}
              placeholder="An event title..."
            />
          </div>
          <div className="eventTitleFR">
            <span className="cardTitle">Event Title (French) *</span>
            <input
              type="text"
              ref={eventTitleFR}
              required
              maxLength={50}
              value={fields.eventTitleFR}
              onChange={(e) => {
                setFields({ ...fields, eventTitleFR: e.target.value });
                clearRequiredAlert(eventTitleFR);
              }}
              placeholder="Un titre pour l'événement..."
            />
          </div>
        </div>

        {/* DESC */}

        <div className="eventDescriptions">
          <div className="eventBody">
            <span className="cardTitle">Event Description</span>
            <textarea
              type="text"
              maxLength={250}
              value={fields.eventDesc}
              onChange={(e) => {
                setFields({ ...fields, eventDesc: e.target.value });
              }}
              placeholder="An event description..."
            />
          </div>
          <div className="eventBodyFR">
            <span className="cardTitle">Event Description (French)</span>
            <textarea
              type="text"
              maxLength={250}
              value={fields.eventDescFR}
              onChange={(e) => {
                setFields({ ...fields, eventDescFR: e.target.value });
              }}
              placeholder="Une description pour l'événement..."
            />
          </div>
        </div>

        {/* DETAILS */}

        <div className="festivalEventDetails">
          <div className="eventLink">
            <span className="cardTitle">EventBrite Link</span>
            <input
              type="text"
              value={fields.eventbriteLink}
              onChange={(e) => {
                setFields({ ...fields, eventbriteLink: e.target.value });
              }}
              placeholder="www.eventbrite.com/event..."
            />
          </div>
          <div className="ticketPrice">
            <span className="cardTitle">Ticket Price</span>
            <input
              type="number"
              value={fields.cost}
              onChange={(e) => {
                setFields({ ...fields, cost: e.target.value });
              }}
              placeholder="$30.00"
            />
          </div>
          <div className="eventLocation">
            <span className="cardTitle">Location</span>
            <input
              type="text"
              maxLength={50}
              value={fields.location}
              onChange={(e) => {
                setFields({ ...fields, location: e.target.value });
              }}
              placeholder="44 Some St., Somewhere, Canada..."
            />
          </div>
          <div className="eventDate">
            <span className="cardTitle">Date *</span>
            <input
              type="date"
              value={GetInputDateFormat(fields.date)}
              onChange={(e) => {
                let fixedDate = e.target.valueAsDate;
                fixedDate.setUTCDate(fixedDate.getUTCDate() + 1);
                fixedDate.setUTCHours(0);
                fixedDate.setUTCMinutes(0);
                fixedDate.setUTCSeconds(0);
                setFields({ ...fields, date: fixedDate });
              }}
            />
          </div>
        </div>

        {/* LINEUP */}
        <div className="lineUpList">
          <span className="cardTitle">
            LineUp
            <MdAddCircle
              onClick={(e) => setAddLineUp(true)}
              className="addIcon"
            />
          </span>
          {addLineUp ? <AddLineUpItem /> : <LineUpList />}
        </div>
      </ScrollContainer>
    </div>
  );
}

export default AddFestivalEvent;
