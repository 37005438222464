import React, { useState, useEffect, useRef } from 'react'
// CSS
import "./Edit.css"
import "./EditAccount.css"
// ICONS
import { MdClose, MdAddCircle } from 'react-icons/md'
// SCROLL
import ScrollContainer from 'react-indiana-drag-scroll'
// FIREBASE GET/SET/DELETE DATA
import { GetDataById } from '../../data/GetData.js'
import { SetVolunteer } from '../../data/SetData.js'
import { DeleteDataById } from '../../data/DeleteData.js'
// TOP NAV
import TopNav from './TopNav'
// PLACEHOLDER
import placeholder from '../../img/Placeholder_Profile_Mono.png'
// MODALS
import AddEmergencyContact from '../../modals/AddEmergencyContact.js'

export const EditAccounts = props => {

  //#region STATES
  //====================================================
  // ID
  const [dataId, setDataId] = useState(props.match.params.accountId ? props.match.params.accountId : null);
  // IS EDITING
  const [isEditing, setIsEditing] = useState(dataId ? true : false);
  // FETCHED DATA
  const [accountData, setAccountData] = useState();
  // UPDATE STATE
  const [update, setUpdate] = useState("Idle");
  // MODAL CONTROLLER
  const [modal, setModal] = useState(false);
  // FIELDS
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [fields, setFields] = useState({
    fullName: '',
    phone: '',
    email: '',
    bio: "",
    image: "",
    super: false,
    contacts: ""
  })
  // DIALOG PROPERTIES
  const [dialogDep, setDialogDep] = useState({
    itemName: "",
    edited: false,
    type: "",
    show: false,
  })
  //====================================================
  //#endregion

  //#region REQUIRED FIELDS
  //====================================================
  // REQUIRED FIELD REFERENCES
  const volunteerFullname = useRef();
  const volunteerEmail = useRef();
  const volunteerPhone = useRef();
  const volunteerECs = useRef();
  // REQUIRED FIELD SETUP
  let requiredRefs = [volunteerFullname, volunteerEmail, volunteerPhone, volunteerECs];
  let requiredStates = [fields.fullName, fields.email, fields.phone, emergencyContacts];
  const MINIMUM = 1;
  let isSafe = !!(fields.fullName && fields.email && fields.phone && emergencyContacts.length >= MINIMUM);
  // CLEAR REQUIRED FIELD CLASS
  const clearRequiredAlert = (providedRef) => {
    if (!providedRef) {
      requiredRefs.forEach((ref) => {
        ref.current.classList.remove('requiredAlert')
      })
    } else {
      providedRef.current.classList.remove('requiredAlert')
    }
  }
  // ADD REQUIRED FIELD CLASS
  const warnUser = () => {
    requiredStates.forEach((field, i) => {
      if (!field) requiredRefs[i].current.classList.add('requiredAlert')
      if (Array.isArray(field)) {
        if (field.length < MINIMUM) requiredRefs[i].current.classList.add('requiredAlert')
      }
    })
  }
  //====================================================
  //#endregion

  //#region DATA FETCH
  //====================================================
  // GETTING DATA
  useEffect(() => {
    if (dataId) {
      const states = [setAccountData]
      const collections = ['volunteers']
      collections.forEach((collection, index) => {
        GetDataById(collection, states[index], dataId);
      });
      props.history.replace('/edit/account/' + dataId)
      setIsEditing(true);
    }
  }, [update])
  // SETTING DATA
  useEffect(() => {
    if (accountData) {
      setFields({
        fullName: accountData.full_name,
        phone: accountData.phone_number,
        email: accountData.email,
        bio: accountData.bio,
        image: accountData.profile_picture,
        super: accountData.isSupervisor,
      });
      setEmergencyContacts(accountData.emergency_contacts)
      setAssignedTasks(accountData.tasks)
      setDialogDep({ ...dialogDep, itemName: accountData.full_name })
    }
  }, [accountData])
  //====================================================
  //#endregion

  //#region DATA HANDLING
  //====================================================
  // HANDLING SAVE
  const handleSave = () => {
    if (isSafe) {
      let tempData = {
        id: dataId,
        full_name: fields.fullName,
        phone_number: fields.phone,
        email: fields.email,
        emergency_contacts: emergencyContacts,
        tasks: [],
        bio: fields.bio,
        isSupervisor: fields.super,
        profile_picture: fields.image
      }
      // Choosing either SetDataById() or SetData() depending if the user is editing or not
      SetVolunteer(tempData, setDataId, setUpdate);
    } else {
      warnUser();
    }
  }
  // HANDLING DELETE
  const handleDelete = () => {
    DeleteDataById("volunteers", dataId, accountData.image, 'accounts', props.history)
    // Go back to home after deleting
  }
  // HANDLING EC DELETE
  const handleContacts = (index) => {
    let tempArray = emergencyContacts;
    tempArray.splice(index, 1);
    setEmergencyContacts(tempArray);
    setFields({ ...fields, contacts: emergencyContacts });
    setDialogDep({ ...dialogDep, edited: true })
  }
  // HANDLING UPDATE STATE
  if (update === "Done") {
    setTimeout(() => setUpdate("Idle"), 1000)
  }
  //====================================================
  //#endregion

  return (
    <main className='editVolunteerContainer'>
      <TopNav history={props.history} isEditing={isEditing} handleSave={handleSave} handleDelete={handleDelete} update={update} dialogDep={dialogDep} setDialogDep={setDialogDep} />
      <ScrollContainer ignoreElements="input, textarea, .tasksContainer" className='editFieldContainer'>
        <img className="accountImagePreview preview" alt="volunteer profile" src={fields.image ? (fields.image.name ? URL.createObjectURL(fields.image) : fields.image) : placeholder} />
        <div className="volInfoContainer">
          <div className='volBio'>
            <span className='cardTitle'>Biography</span>
            <p className="volBioContent">{fields.bio}</p>
          </div>
          {/* <span className='cardTitle'>Supervisor {fields.super ? <MdCheck className="iconSuper super" /> : <MdClose className="iconSuper" />}</span> */}
        </div>
        <div className='volName'>
          <span className='cardTitle'>Full Name *</span>
          <input
            type='text'
            required
            ref={volunteerFullname}
            value={fields.fullName}
            maxLength={100}
            onChange={e => { setFields({ ...fields, fullName: e.target.value }); setDialogDep({ ...dialogDep, edited: true }); clearRequiredAlert(volunteerFullname) }}
            placeholder='A full name...'
          />
        </div>
        <div className='volEmail'>
          <span className='cardTitle'>Email *</span>
          <input
            type='email'
            required
            ref={volunteerEmail}
            value={fields.email}
            maxLength={50}
            onChange={e => { setFields({ ...fields, email: e.target.value }); setDialogDep({ ...dialogDep, edited: true }); clearRequiredAlert(volunteerEmail) }}
            placeholder='An email...'
          />
        </div>
        <div className='volPhone'>
          <span className='cardTitle'>Phone *</span>
          <input
            type='tel'
            required
            ref={volunteerPhone}
            value={fields.phone}
            maxLength={10}
            onChange={e => { setFields({ ...fields, phone: e.target.value }); setDialogDep({ ...dialogDep, edited: true }); clearRequiredAlert(volunteerPhone) }}
          />
        </div>
        <div className="volContactsAndTasks">
          <div className="emergencyContacts">
            <span className="cardTitle">Emergency Contact(s) *<MdAddCircle onClick={(e) => {setModal(true); clearRequiredAlert(volunteerECs)}} className="addIcon" /></span>
            <span className="cardCaption">{emergencyContacts.length}/2</span>
            <div className="contactsContainer" ref={volunteerECs}>
              {emergencyContacts && emergencyContacts.map((contact, index) => {
                return (
                  <div className="contactContainer" key={index}>
                    <div className="infoContainer">
                      <span className="cardTitle"><span className="cardSubTitle">Name:</span> {contact.name}</span>
                      <span className="cardCaption"><span className="cardSubTitle">Phone:</span> {contact.phone_number}</span>
                      <span className="cardCaption"><span className="cardSubTitle">Email:</span> {contact.email}</span>
                    </div>
                    <MdClose className="deleteIcon" onClick={(e) => handleContacts(index)} />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="volTasks">
            <span className="cardTitle">Assigned Task(s)</span>
            <ScrollContainer className="tasksContainer">
              {assignedTasks && assignedTasks.map((task, index) => {
                return (
                  <div className="volTaskContainer" key={index}>
                    <span className="cardTitle"><span className="cardSubTitle">Title:</span> {task.name.en}</span>
                    <span className="cardCaption"><span className="cardSubTitle">Start Date:</span> {task.start_date.toDate().toDateString()}</span>
                  </div>
                )
              })}
            </ScrollContainer>
          </div>
        </div>
      </ScrollContainer>
      {modal ? <AddEmergencyContact emergencyContacts={emergencyContacts} setEmergencyContacts={setEmergencyContacts} setModal={setModal} /> : null}
    </main>
  )
}

export default EditAccounts
