import React, { useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
// CSS
import "./Artists.css"
// ICONS
import { MdAddCircle } from "react-icons/md"
// HORIZONTAL SCROLLING
import ScrollContainer from 'react-indiana-drag-scroll'
// PLACEHOLDER
import placeholder from '../../img/Placeholder_Profile_Mono.png'
// FIREBASE DATA
import { GetData } from '../../data/GetData.js'

function Artists(props) {

    const history = useHistory();

    const [artists, setArtists] = useState();

    //#region FETCH
    //====================================================
    useEffect(() => {
        const states = [setArtists]
        const collections = ['acts']
        collections.forEach((collection, index) => {
            GetData(collection, states[index]);
        })
    }, [])
    //====================================================
    //#endregion

    return (
        <main className="artistsContainer">
            <div className="artistsTitle">
                <h1 className="title">
                    Artists
                    <MdAddCircle onClick={(e) => history.push("/edit/artist/")} className="addIcon" />
                </h1>
            </div>
            <ScrollContainer className="cardContainer">
                {/* CARDS */}
                {artists && artists.map(function (artist) {
                    return (
                        <NavLink to={"edit/artist/" + artist.id} key={artist.id} className="artistCard" style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 69.46%, rgba(0, 0, 0, 0.5) 100%), url(${artist.image ? artist.image : placeholder})`, backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat', backgroundPosition: 'center'
                        }}>
                            <span className="cardTitle">{artist.name}</span>
                        </NavLink>
                    )
                })
                }
            </ScrollContainer>
        </main>
    )
}

export default Artists