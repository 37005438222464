const GetInputDateFormat = (date) => {
    if (date) {
        let newDate = date;
        // IF FIREBASE TIMESTAMP
        if (date.seconds) {
            newDate = newDate.toDate();
        }
        // IF NOT A DATE
        if (typeof newDate.getMonth !== 'function') {
            newDate = new Date(date);
        }
        // BUILDING THE STRING
        if(newDate) {
        let year = newDate.getFullYear();
        let month = newDate.getMonth() + 1;
        let day = newDate.getDate();
        day = day.toString().padStart(2, '0');
        month = month.toString().padStart(2, '0');
        return `${year}-${month}-${day}`
        }
    }
}

const GetDateFormat = (date) => {
    if (date) {
        let newDate = date;
        // IF FIREBASE TIMESTAMP
        if (date.seconds) {
            newDate = newDate.toDate();
        }
        // IF NOT A DATE
        if (typeof newDate.getMonth !== 'function') {
            newDate = new Date(date);
        }
        // BUILDING THE STRING
        if(newDate) {
        return newDate;
        }
    }
}

const GetInputTimeFormat = (date) => {
    if (date) {
        let newDate = date;
        if(newDate.toString().length <= 5){
            return date;
        }
        // IF FIREBASE TIMESTAMP
        if (date.seconds) {
            newDate = newDate.toDate();
        }
        // IF NOT A DATE
        if (typeof newDate.getMonth !== 'function') {
            newDate = new Date(date);
        }
        // BUILDING THE STRING
        if(newDate) {
        let hours = newDate.getHours();
        hours = hours.toString().trim().padStart(2, '0');
        let minutes = newDate.getMinutes();
        minutes = minutes.toString().trim().padStart(2, '0');
        return `${hours}:${minutes}`
        }
    }
}

export { GetInputDateFormat, GetInputTimeFormat, GetDateFormat };