import React from 'react'
import { NavLink, Route } from 'react-router-dom'
import Tasks from './tasks/Tasks'
import Accounts from './accounts/Accounts'
import './Volunteer.css'

function Volunteer (props) {
  return (
    <main className='volunteerContainer'>
      <div className='topNav'>
        <h2 className='volunteerTitle'>Volunteer</h2>
        <div className='tabContainer'>
          <NavLink to='accounts'>
            <h1 className="title">Accounts</h1>
          </NavLink>
          <NavLink to='tasks'>
            <h1 className="title">Tasks</h1>
          </NavLink>
        </div>
      </div>
      <Route path='/accounts'>
        <Accounts />
      </Route>
      <Route path='/tasks'>
        <Tasks />
      </Route>
    </main>
  )
}

export default Volunteer
