import React, { useState } from 'react'
import "./Dialog.css"

// ICONS
import { MdDelete, MdArrowBack } from 'react-icons/md'

function Dialog({ handleDelete, history, setDialogDep, dialogDep }) {

    // Deleting a list item like lineUp items
    const DeleteItem = () => {
        return (
            <div className={dialogDep.show ? "dialog show" : "dialog"}>
                <div className="dialog-content">
                    <div className="dialog-header">
                        <span className="cardTitle">Are you sure you want to delete this item?</span>
                    </div>
                    <div className="dialog-button-container">
                        <button onClick={(e) => setDialogDep({ ...dialogDep, show: false })} className="btnClose secondary"><MdArrowBack className="editIcon" />Back</button>
                        <button className="btnDelete caution" onClick={(e) => { setDialogDep({ ...dialogDep, show: false }); handleDelete() }} ><MdDelete className="editIcon" />Delete</button>
                    </div>
                </div>
            </div>
        )
    }

    // Reminder when a user has edited a feild but has not saved.
    const SaveReminder = () => {
        return (
            <div className={dialogDep.show ? "dialog show" : "dialog"}>
                <div className="dialog-content">
                    <div className="dialog-header">
                        <span className="dialog-title">Are you sure you want to quit without saving?</span>
                    </div>
                    <div className="dialog-button-container">
                        <button onClick={(e) => setDialogDep({ ...dialogDep, show: false })} className="btnClose secondary">
                            <MdArrowBack className="editIcon" />
                            Back
                        </button>

                        <button className="btnDelete caution" onClick={(e) => history.goBack()}>Continue without Saving</button>
                    </div>
                </div>
            </div>
        )
    }
    // when a user is trying to delete a top level item.
    const DeleteImportantItem = () => {
        const [value, setValue] = useState()
        const [error, setError] = useState(false)

        return (
            <div className={dialogDep.show ? "dialog show" : "dialog"}>
                <div className="dialog-content long">
                    <div className="dialog-header">
                        <span className="dialog-title">
                            Delete<span style={{ fontWeight: "bold", marginLeft: "0.5rem", color: "var(--black)" }}>{`${dialogDep.itemName}`}</span>
                        </span>
                    </div>
                    <div className="dialog-caption">
                        <span className="cardTitle">
                            To proceed, please type the event name to delete:
                        </span>
                        <input
                            type="text"
                            required
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Enter name here"
                        />
                        <span className={error ? "dialogError show" : "dialogError"}>
                            The name you entered does not match, please try again.
                        </span>
                    </div>
                    <div className="dialog-button-container">
                        <button onClick={(e) => setDialogDep({ ...dialogDep, show: false })} className="btnClose secondary">
                            <MdArrowBack className="editIcon" />
                            Back
                        </button>

                        <button className="btnDelete caution" onClick={(e) => {
                            if (value === dialogDep.itemName) {
                                setDialogDep({ ...dialogDep, show: false })
                                handleDelete()
                            } else {
                                setError(true)
                            }
                        }} >
                            <MdDelete className="editIcon" />
                            Delete
                            </button>
                    </div>
                </div>
            </div>
        )
    }
    switch (dialogDep.type) {
        case 'delete':
            return (
                <DeleteItem />
            )
        case 'noSave':
            return (
                <SaveReminder />
            )
        case 'cautionDelete':
            return (
                <DeleteImportantItem />
            )
        default:
            return (
                <DeleteItem />
            )
    }
}

export default Dialog;



