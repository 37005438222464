import React from "react";
// REACT ICONS
import { MdVerifiedUser } from 'react-icons/md'
// OGF LOGO
import OGFLogo from "../img/OGF_Logo_White.png";
// CSS
import './Login.css';

const Login = (props) => {

  const {
    email,
    setEmail,
    password,
    setPassword,
    handleLogin,
    loginError,
    setReset
  } = props;

  return (
    <div className="login">
      <img
        src={OGFLogo}
        alt="logo"
      />
      <h2 className="titleLogin">Admin Dashboard</h2>
      <form  onSubmit={handleLogin} className="loginContainer">
        <input
          type="text"
          autoFocus
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="example@email.com"
        />
        
        <input
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="password"
        />
        <p hidden={!loginError} className="errorMsg">{loginError}</p>
        <div className="btnLoginContainer">
        <span onClick={()=> setReset(true)} className="btnForgot">I forgot my password</span>
        <button className="btnLogin primary" type="submit"><MdVerifiedUser />Log in</button>
        </div>
      </form>
    </div>
  );
};

export default Login;