import React, { useState, useEffect, useRef } from 'react'
import { ts } from "../../firebase";
// HORIZONTAL SCROLL
import ScrollContainer from 'react-indiana-drag-scroll'
// REACT ICONS
import { MdClose, MdAddCircle } from 'react-icons/md'
// CSS
import "./Edit.css"
import "./EditPolls.css"
// FIREBASE GET/SET DATA
import { GetDataById } from '../../data/GetData.js'
import { SetDataById } from '../../data/SetData.js'
import { DeleteDataById } from '../../data/DeleteData.js'
import { GetInputDateFormat } from '../../data/GetInputDateFormat.js'
// TOP NAV
import TopNav from './TopNav'
// MODALS
import AddPollChoice from '../../modals/AddPollChoice.js'

export const EditPolls = (props) => {

    //#region STATES
    //====================================================
    // ID
    const [dataId, setDataId] = useState(props.match.params.pollId ? props.match.params.pollId : null);
    // IF USER IS EDITING OR CREATING
    const [isEditing, setIsEditing] = useState(dataId ? true : false);
    // FETCHED DATA
    const [pollItem, setPollItem] = useState();
    // UPDATE STATE
    const [update, setUpdate] = useState("Idle");
    // UPLOAD PROGRESS
    const [uploadProg, setUploadProg] = useState(null);
    // DIALOG PROPERTIES
    const [dialogDep, setDialogDep] = useState({
        itemName: "",
        edited: false,
        type: "",
        show: false,
    })
    // MODAL CONTROLLER
    const [modal, setModal] = useState(false);
    // FIELDS
    const [pollVotes, setPollVotes] = useState([]);
    const [fields, setFields] = useState({
        pollTitle: "",
        pollTitleFR: "",
        pollCaption: "",
        pollCaptionFR: "",
        pollStartDate: new Date(),
        pollEndDate: new Date(),
        votes: [
        ]
    })
    //====================================================
    //#endregion

    //#region REQUIRED FIELDS
    //====================================================
    // REQUIRED FIELD REFERENCES
    const pollTitle = useRef();
    const pollChoices = useRef();
    // REQUIRED FIELD SETUP
    let requiredRefs = [pollTitle, pollChoices];
    let requiredStates = [fields.pollTitle, pollVotes];
    const MINIMUM = 2;
    let isSafe = !!(fields.pollTitle && pollVotes.length >= MINIMUM)
    // CLEAR REQUIRED FIELD CLASS
    const clearRequiredAlert = (providedRef) => {
        if (!providedRef) {
            requiredRefs.forEach((ref) => {
                ref.current.classList.remove('requiredAlert')
            })
        } else {
            providedRef.current.classList.remove('requiredAlert')
        }
    }
    // ADD REQUIRED FIELD CLASS
    const warnUser = () => {
        requiredStates.forEach((field, i) => {
            if (!field) requiredRefs[i].current.classList.add('requiredAlert')
            if (Array.isArray(field)) {
                if (field.length < MINIMUM) requiredRefs[i].current.classList.add('requiredAlert')
            }
        })
    }
    //====================================================
    //#endregion

    //#region DATA FETCH
    //====================================================
    // GETTING DATA
    useEffect(() => {
        if (dataId) {
            const states = [setPollItem]
            const collections = ['polls']
            collections.forEach((collection, index) => {
                GetDataById(collection, states[index], dataId);
            });
            props.history.replace('/edit/poll/' + dataId)
            setIsEditing(true);
        }
    }, [update])
    // SETTING FIELDS WITH DATA
    useEffect(() => {
        if (pollItem) {
            setFields({
                pollTitle: pollItem.title.en,
                pollTitleFR: pollItem.title.fr,
                pollCaption: pollItem.caption.en,
                pollCaptionFR: pollItem.caption.fr,
                pollStartDate: pollItem.start_date,
                pollEndDate: pollItem.end_date,
                votes: []
            });
            setPollVotes(pollItem.votes)
            setDialogDep({ ...dialogDep, itemName: pollItem.title.en })
        }
    }, [pollItem])
    //====================================================
    //#endregion

    //#region DATA HANDLING
    //====================================================
    // HANDLING VOTE DELETE
    const handleVotes = (index) => {
        let tempArray = pollVotes;
        tempArray.splice(index, 1);
        setPollVotes(tempArray);
        setFields({ ...fields, votes: pollVotes });
    }
    // HANDLING POLL SAVE
    const handleSave = (e) => {
        if (isSafe) {
            let pollData = {
                id: dataId,
                title:
                {
                    en: fields.pollTitle,
                    fr: fields.pollTitleFR
                },
                caption:
                {
                    en: fields.pollCaption,
                    fr: fields.pollCaptionFR
                },
                start_date: fields.pollStartDate.seconds ? fields.pollStartDate : ts.fromDate(fields.pollStartDate),
                end_date: fields.pollEndDate.seconds ? fields.pollEndDate : ts.fromDate(fields.pollEndDate),
                votes: pollVotes
            }
            SetDataById("polls", pollData, setDataId, setUpdate);
            clearRequiredAlert()
        } else {
            warnUser()
        }
    }
    // HANDLING POLL DELETE
    const handleDelete = () => {
        DeleteDataById("polls", dataId, null, '', props.history)
    }
    // HANDLING UPDATE STATE
    if (update === "Done") {
        setTimeout(() => setUpdate("Idle"), 1000)
    }
    //====================================================
    //#endregion

    return (
        <main className="editPollsContainer">
            <TopNav history={props.history} isEditing={isEditing} handleSave={handleSave} handleDelete={handleDelete} uploadProg={uploadProg} update={update} dialogDep={dialogDep} setDialogDep={setDialogDep} />
            <ScrollContainer ignoreElements="input, textarea" className="editFieldContainer">
                <div className="pollTitle">
                    <span className="cardTitle">Poll Title *</span>
                    <input
                        type="text"
                        required
                        ref={pollTitle}
                        value={fields.pollTitle}
                        maxLength={50}
                        onChange={(e) => { setFields({ ...fields, pollTitle: e.target.value }); setDialogDep({ ...dialogDep, edited: true }); clearRequiredAlert(pollTitle) }}
                        placeholder="A poll title..."
                    />
                </div>
                <div className="pollTitleFR">
                    <span className="cardTitle">Poll Title (French)</span>
                    <input
                        type="text"
                        value={fields.pollTitleFR}
                        maxLength={50}
                        onChange={(e) => { setFields({ ...fields, pollTitleFR: e.target.value }); setDialogDep({ ...dialogDep, edited: true }) }}
                        placeholder="Un titre pour le sondage..."
                    />
                </div>
                <div className="pollCaption">
                    <span className="cardTitle">Caption</span>
                    <input
                        type="text"
                        value={fields.pollCaption}
                        maxLength={50}
                        onChange={(e) => { setFields({ ...fields, pollCaption: e.target.value }); setDialogDep({ ...dialogDep, edited: true }) }}
                        placeholder="A poll caption..."
                    />
                </div>
                <div className="pollCaptionFR">
                    <span className="cardTitle">Caption (French)</span>
                    <input
                        type="text"
                        value={fields.pollCaptionFR}
                        maxLength={50}
                        onChange={(e) => { setFields({ ...fields, pollCaptionFR: e.target.value }); setDialogDep({ ...dialogDep, edited: true }) }}
                        placeholder="Une caption pour le sondage..."
                    />
                </div>
                <div className="pollDate">
                    <div className="pollStartDate">
                        <span className="cardTitle">Start Date *</span>
                        <input
                            type="date"
                            required
                            value={GetInputDateFormat(fields.pollStartDate)}
                            onChange={(e) => {
                                let fixedDate = e.target.valueAsDate;
                                fixedDate.setUTCDate(fixedDate.getUTCDate() + 1);
                                fixedDate.setUTCHours(0);
                                fixedDate.setUTCMinutes(0);
                                fixedDate.setUTCSeconds(0);
                                setFields({ ...fields, pollStartDate: fixedDate });
                                setDialogDep({ ...dialogDep, edited: true })
                            }}
                        />
                    </div>
                    <div className="pollEndDate">
                        <span className="cardTitle">End Date *</span>
                        <input
                            type="date"
                            required
                            value={GetInputDateFormat(fields.pollEndDate)}
                            onChange={(e) => {
                                let fixedDate = e.target.valueAsDate;
                                fixedDate.setUTCDate(fixedDate.getUTCDate() + 1);
                                fixedDate.setUTCHours(0);
                                fixedDate.setUTCMinutes(0);
                                fixedDate.setUTCSeconds(0);
                                setFields({ ...fields, pollEndDate: fixedDate });
                                setDialogDep({ ...dialogDep, edited: true })
                            }}
                        />
                    </div>
                </div>
                <div className="pollChoices">
                    <span className="cardTitle">Poll Choices *<MdAddCircle onClick={(e) => { setModal(true); clearRequiredAlert(pollChoices) }} className="addIcon" /></span>
                    <span className="cardCaption">{pollVotes.length}/4</span>
                    <div className="choicesContainer" ref={pollChoices}>
                        <div className="choiceHeaders">
                            <span className="cardCaption">Name</span>
                            <span className="cardCaption">Vote Count</span>
                        </div>
                        {pollVotes && pollVotes.map((vote, index) => {
                            return (
                                <div className="choiceContainer" key={vote.name.replace(' ', '') + index}>
                                    <span className="choiceTitle cardTitle">{vote.name}</span>
                                    <span className="choiceVotes cardCaption">{vote.count}</span>
                                    <MdClose className="deleteIcon" onClick={(e) => { handleVotes(index); setDialogDep({ ...dialogDep, edited: true }) }} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </ScrollContainer>
            {modal ? <AddPollChoice pollVotes={pollVotes} setPollVotes={setPollVotes} setModal={setModal} /> : null}
        </main>
    )
}

export default EditPolls