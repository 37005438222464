import React, { useState, useEffect, useRef } from "react";
import { ts } from "../../firebase";
// VERTICAL SCROLLING
import ScrollContainer from "react-indiana-drag-scroll";
// REACT ICONS
import { MdAddCircle } from "react-icons/md";
// CSS
import "./Edit.css";
import "./EditFestival.css";
// FIREBASE GET/SET/DELETE DATA
import { GetDataById } from "../../data/GetData.js";
import { SetDataUploadFestival } from "../../data/SetData.js";
import { DeleteDataById } from "../../data/DeleteData.js";
import { GetInputDateFormat } from "../../data/GetInputDateFormat.js";
// TOP NAV
import TopNav from "./TopNav";
// PLACEHOLDER
import Placeholder from "../../img/Placeholder_Mono.png";
// DIALOG
import AddFestivalEvent from "../../modals/AddFestivalEvent.js";

export const EditFestival = (props) => {
  //#region STATES
  //====================================================
  // ID
  const [dataId, setDataId] = useState(
    props.match.params.festivalId ? props.match.params.festivalId : null
  );
  // IS EDITING
  const [isEditing, setIsEditing] = useState(dataId ? true : false);
  // FETCHED DATA
  const [festivalData, setFestivalData] = useState();
  // UPDATE STATE
  const [update, setUpdate] = useState("Idle");
  // UPLOAD PROGRESS
  const [uploadProg, setUploadProg] = useState(null);
  // MODAL CONTROLLER
  const [modal, setModal] = useState(false);
  // SET SELECTED EVENT
  const [selectedEvent, setSelectedEvent] = useState();
  // FIELDS
  const [festivalEvents, setFestivalEvents] = useState([]);
  const [fields, setFields] = useState({
    year: "",
    startDate: new Date(),
    endDate: new Date(),
    location: "",
    image: "",
  });
  // DIALOG PROPERTIES
  const [dialogDep, setDialogDep] = useState({
    itemName: fields.year,
    edited: false,
    type: "cautionDelete",
    show: false,
  });
  //====================================================
  //#endregion

  //#region REQUIRED FIELDS
  //====================================================
  // REQUIRED FIELD REFERENCES
  const festivalYear = useRef();
  const festivalImage = useRef();
  // REQUIRED FIELD SETUP
  let requiredRefs = [festivalYear, festivalImage];
  let requiredStates = [fields.year, fields.image];
  const MINIMUM = 1;
  let isSafe = !!(fields.year && fields.image);
  // CLEAR REQUIRED FIELD CLASS
  const clearRequiredAlert = (providedRef) => {
    if (!providedRef) {
      requiredRefs.forEach((ref) => {
        ref.current.classList.remove("requiredAlert");
      });
    } else {
      providedRef.current.classList.remove("requiredAlert");
    }
  };
  // ADD REQUIRED FIELD CLASS
  const warnUser = () => {
    requiredStates.forEach((field, i) => {
      if (!field) requiredRefs[i].current.classList.add("requiredAlert");
      if (Array.isArray(field)) {
        if (field.length < MINIMUM)
          requiredRefs[i].current.classList.add("requiredAlert");
      }
    });
  };
  //====================================================
  //#endregion

  //#region DATA FETCH
  //====================================================
  // GETTING DATA
  useEffect(() => {
    if (dataId) {
      const states = [setFestivalData];
      const collections = ["festival_year"];
      collections.forEach((collection, index) => {
        GetDataById(collection, states[index], dataId);
      });
      props.history.replace("/edit/festival/" + dataId);
      setIsEditing(true);
    }
  }, [update]);
  // SETTING DATA
  useEffect(() => {
    if (festivalData) {
      setFields({
        year: festivalData.year,
        startDate: festivalData.start_date,
        endDate: festivalData.end_date,
        location: festivalData.location,
        image: festivalData.image,
      });
      setFestivalEvents(festivalData.events);
      setDialogDep({ ...dialogDep, itemName: festivalData.year });
    }
  }, [festivalData]);
  //====================================================
  //#endregion

  //#region DATA HANDLING
  //====================================================
  // HANDLING SAVE FUNCTION
  const handleSave = () => {
    if (isSafe) {
      let tempData = {
        id: dataId,
        year: fields.year,
        start_date: fields.startDate.seconds
          ? fields.startDate
          : ts.fromDate(fields.startDate),
        end_date: fields.endDate.seconds
          ? fields.endDate
          : ts.fromDate(fields.endDate),
        location: fields.location,
        image: fields.image,
        events: festivalEvents,
      };
      // Choosing either SetDataById() or SetData() depending if the user is editing or not
      SetDataUploadFestival(
        "festival_year",
        tempData,
        setDataId,
        setUploadProg,
        uploadProg,
        setUpdate
      );
    } else {
      warnUser();
    }
  };
  // HANDLE SAVE
  const handleDelete = () => {
    DeleteDataById(
      "festival_year",
      dataId,
      festivalData.image,
      "",
      props.history
    );
  };
  // HANDLING UPDATE STATE
  if (update === "Done") {
    setTimeout(() => setUpdate("Idle"), 1000);
  }
  //====================================================
  //#endregion

  return (
    <main className="editFestivalContainer">
      <TopNav
        history={props.history}
        isEditing={isEditing}
        handleSave={handleSave}
        handleDelete={handleDelete}
        uploadProg={uploadProg}
        update={update}
        dialogDep={dialogDep}
        setDialogDep={setDialogDep}
      />
      <ScrollContainer
        ignoreElements=".festivalEvents, input, textarea"
        className="editFieldContainer"
      >
        <div className="festivalImage">
          <span className="cardTitle">Festival Image</span>
          <input
            type="file"
            required
            ref={festivalImage}
            onChange={(e) => {
              setFields({ ...fields, image: e.target.files[0] });
              setDialogDep({ ...dialogDep, edited: true });
              clearRequiredAlert(festivalImage);
            }}
          />
        </div>
        <img
          className="festivalImagePreview preview"
          src={
            fields.image
              ? fields.image.name
                ? URL.createObjectURL(fields.image)
                : fields.image
              : Placeholder
          }
          alt="festival"
        />
        <div className="festivalTitle">
          <span className="cardTitle">Festival Year *</span>
          <input
            type="number"
            required
            ref={festivalYear}
            value={fields.year}
            onChange={(e) => {
              if (e.target.value.length <= 4)
                setFields({ ...fields, year: e.target.value });
              setDialogDep({ ...dialogDep, edited: true });
              clearRequiredAlert(festivalYear);
            }}
            placeholder="2020..."
          />
        </div>
        <div className="festivalLocation">
          <span className="cardTitle">Location</span>
          <input
            type="text"
            value={fields.location}
            maxLength={50}
            onChange={(e) => {
              setFields({ ...fields, location: e.target.value });
              setDialogDep({ ...dialogDep, edited: true });
            }}
            placeholder="44 Some St., Somewhere, Canada..."
          />
        </div>
        <div className="festivalDates">
          <div className="festivalStartDate">
            <span className="cardTitle">Start Date *</span>
            <input
              type="date"
              required
              value={GetInputDateFormat(fields.startDate)}
              onChange={(e) => {
                let fixedDate = e.target.valueAsDate;
                fixedDate.setUTCDate(fixedDate.getUTCDate() + 1);
                fixedDate.setUTCHours(0);
                fixedDate.setUTCMinutes(0);
                fixedDate.setUTCSeconds(0);
                setFields({ ...fields, startDate: fixedDate });
                setDialogDep({ ...dialogDep, edited: true });
              }}
            />
          </div>
          <div className="festivalEndDate">
            <span className="cardTitle">End Date *</span>
            <input
              type="date"
              required
              value={GetInputDateFormat(fields.endDate)}
              onChange={(e) => {
                let fixedDate = e.target.valueAsDate;
                fixedDate.setUTCDate(fixedDate.getUTCDate() + 1);
                fixedDate.setUTCHours(0);
                fixedDate.setUTCMinutes(0);
                fixedDate.setUTCSeconds(0);
                setFields({ ...fields, endDate: fixedDate });
                setDialogDep({ ...dialogDep, edited: true });
              }}
            />
          </div>
        </div>
        <div className="festivalEvents">
          <span className="cardTitle">
            Events *
            {dataId ? (
              <MdAddCircle
                className="addIcon"
                onClick={(e) => {
                  setModal(true);
                  setDialogDep({ ...dialogDep, edited: true });
                }}
              />
            ) : (
              <span className="cardCaption">Save to add an event</span>
            )}
          </span>
          <ScrollContainer className="events-card-container">
            {festivalEvents &&
              festivalEvents.map((event, index) => {
                return (
                  <div
                    onClick={(e) => {
                      setModal(true);
                      setSelectedEvent(event);
                      setDialogDep({ ...dialogDep, edited: true });
                    }}
                    key={event.id}
                    className="events-card"
                  >
                    <div className="events-thumbnail-container">
                      <img
                        src={
                          event.image
                            ? event.image.name
                              ? URL.createObjectURL(event.image)
                              : event.image
                            : Placeholder
                        }
                        alt="Event"
                      />
                    </div>
                    <div className="events-text-container">
                      <span className="cardTitle">{event.title.en}</span>
                      <span className="cardCaption">
                        {event.date.toDate().toDateString().substring(3, 15)}
                      </span>
                      {/* <MdClose onClick={(e) => handleEvents(index)} /> */}
                    </div>
                  </div>
                );
              })}
          </ScrollContainer>
        </div>
      </ScrollContainer>
      {modal ? (
        <AddFestivalEvent
          setModal={setModal}
          modalStatus={modal}
          history={props.history}
          isEditing={isEditing}
          handleSave={handleSave}
          handleDelete={handleDelete}
          update={update}
          uploadProg={uploadProg}
          setFestivalEvents={setFestivalEvents}
          festivalEvents={festivalEvents}
          setSelectedEvent={setSelectedEvent}
          selectedEvent={selectedEvent}
        />
      ) : null}
    </main>
  );
};

export default EditFestival;
