import React, { useEffect, useState, useRef } from "react";
// HORIZONTAL SCROLL
import ScrollContainer from "react-indiana-drag-scroll";
// ICONS
import { MdFiberManualRecord, MdCheck } from "react-icons/md";
// CSS
import "./Edit.css";
import "./EditTask.css";
// FIREBASE GET/SET/DELETE DATA
import { GetDataById, GetData } from "../../data/GetData.js";
import { SetDataById } from "../../data/SetData.js";
import { DeleteDataById } from "../../data/DeleteData.js";
import {
  GetInputDateFormat,
  GetInputTimeFormat,
  GetDateFormat,
} from "../../data/GetInputDateFormat.js";
// TOP NAV
import TopNav from "./TopNav";
// PLACEHOLDER
import placeholder from "../../img/Placeholder_Profile_Mono.png";

export const EditTask = (props) => {
  //#region STATES
  //====================================================
  // ID
  const [dataId, setDataId] = useState(
    props.match.params.taskId ? props.match.params.taskId : null
  );
  // IF USER IS EDITING OR CREATING
  const [isEditing, setIsEditing] = useState(dataId ? true : false);
  // FETCHED DATA
  const [taskData, setTaskData] = useState();
  // UPDATE STATE
  const [update, setUpdate] = useState("Idle");
  // SELECTOR
  const [cardID, setCardID] = useState([]);
  // FIELDS
  const [volunteers, setVolunteers] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const [fields, setFields] = useState({
    taskTitle: "",
    taskTitleFR: "",
    taskDescription: "",
    taskDescriptionFR: "",
    taskNotes: "",
    taskNotesFR: "",
    taskStartDate: new Date(),
    taskEndDate: new Date(),
    taskLocation: "",
    taskPeople: [],
  });
  // DIALOG PROPERTIES
  const [dialogDep, setDialogDep] = useState({
    itemName: fields.taskTitle,
    edited: false,
    type: "",
    show: false,
  });
  //====================================================
  //#endregion

  //#region REQUIRED FIELDS
  //====================================================
  // REQUIRED FIELD REFERENCES
  const taskTitle = useRef();
  const taskTitleFR = useRef();
  const taskVolunteers = useRef();
  // REQUIRED FIELD SETUP
  let requiredRefs = [taskTitle, taskTitleFR, taskVolunteers];
  let requiredStates = [fields.taskTitle, fields.taskTitleFR, cardID];
  const MINIMUM = 1;
  let isSafe = !!(
    fields.taskTitle &&
    fields.taskTitleFR &&
    cardID.length >= MINIMUM
  );
  // CLEAR REQUIRED FIELD CLASS
  const clearRequiredAlert = (providedRef) => {
    if (!providedRef) {
      requiredRefs.forEach((ref) => {
        ref.current.classList.remove("requiredAlert");
      });
    } else {
      providedRef.current.classList.remove("requiredAlert");
    }
  };
  // ADD REQUIRED FIELD CLASS
  const warnUser = () => {
    requiredStates.forEach((field, i) => {
      if (!field) requiredRefs[i].current.classList.add("requiredAlert");
      if (Array.isArray(field)) {
        if (field.length < MINIMUM)
          requiredRefs[i].current.classList.add("requiredAlert");
      }
    });
  };
  //====================================================
  //#endregion

  //#region DATA FETCH
  //====================================================
  // GETTING DATA
  useEffect(() => {
    if (dataId) {
      const states = [setTaskData];
      const collections = ["tasks"];
      collections.forEach((collection, index) => {
        GetDataById(collection, states[index], dataId);
      });
      props.history.replace("/edit/task/" + dataId);
      setIsEditing(true);
    }
  }, [update]);
  // SETTING FIELDS WITH DATA
  useEffect(() => {
    if (taskData) {
      setFields({
        taskTitle: taskData.name.en,
        taskTitleFR: taskData.name.fr,
        taskDescription: taskData.description.en,
        taskDescriptionFR: taskData.description.fr,
        taskNotes: taskData.notes.en,
        taskNotesFR: taskData.notes.fr,
        taskStartDate: taskData.start_date,
        taskEndDate: taskData.end_date,
        taskStartTime: taskData.start_date,
        taskEndTime: taskData.end_date,
        taskLocation: taskData.location,
        taskPeople: taskData.volunteers_assigned,
      });
      setAssigned(taskData.volunteers_assigned);
      setCardID(taskData.volunteers_assigned.map((volunteer) => volunteer.id));
      setDialogDep({ ...dialogDep, itemName: taskData.name.en });
    }
  }, [taskData]);
  // GETTING VOLUNTEERS
  useEffect(() => {
    const states = [setVolunteers];
    const collections = ["volunteers"];
    collections.forEach((collection, index) => {
      GetData(collection, states[index]);
    });
  }, []);
  //====================================================
  //#endregion

  useEffect(() => {
    handleVolunteerTasks(true);
  }, [dataId]);
  //#region DATA HANDLING
  //====================================================
  // HANDLING SAVE FUNCTION
  const handleSave = () => {
    if (isSafe) {
      // Creating temporary array to store selected volunteers to go into task.volunteers_assigned array property
      let tempVolunteers = [];
      // If volunteers were selected
      if (cardID) {
        volunteers.forEach((volunteer, index) => {
          cardID.forEach((volId) => {
            // If the volunteer id matches the selected volunteer id
            if (volunteer.id === volId) {
              let { full_name, id, isSupervisor, profile_picture } = volunteer;
              tempVolunteers.push({
                full_name,
                id,
                isSupervisor,
                profile_picture,
              });
            }
          });
        });
      }
      let tempData = {
        id: dataId,
        name: {
          en: fields.taskTitle,
          fr: fields.taskTitleFR,
        },
        description: {
          en: fields.taskDescription,
          fr: fields.taskDescriptionFR,
        },
        notes: {
          en: fields.taskNotes,
          fr: fields.taskNotesFR,
        },
        start_date: fields.taskStartDate,
        end_date: fields.taskEndDate,
        location: fields.taskLocation,
        volunteers_assigned: tempVolunteers,
        volunteers_id: cardID,
      };
      SetDataById("tasks", tempData, setDataId, setUpdate);
      clearRequiredAlert();
    } else {
      warnUser();
    }
  };
  // HANDLE DELETE
  const handleDelete = () => {
    handleVolunteerTasks(false)
    DeleteDataById("tasks", dataId, null, "tasks", props.history);
  };
  // HANDLE VOLUNTEERS
  const handleSelection = (e) => {
    let id = e.target.closest(".task-volunteer-card").id;
    if (cardID.includes(id)) {
      let tempArray = [];
      if (Array.isArray(cardID)) {
        tempArray = cardID.filter((ID) => ID !== id);
      }
      setCardID(tempArray);
    } else {
      setCardID([...cardID, id]);
    }
    setDialogDep({ ...dialogDep, edited: true });
  };
  // HANDLING VOLUNTEER TASKS
  const handleVolunteerTasks = (isSaving) => {
    // Temporary task for volunteer "tasks" array
    let tempTask = {
      start_date: fields.taskStartDate,
      end_date: fields.taskEndDate,
      name: {
        en: fields.taskTitle,
        fr: fields.taskTitleFR,
      },
      id: dataId,
    };

    // Creating temporary array of all volunteers
    let tempAllVolunteers = volunteers.map((vol) => {
      // Removing task that matches the id of current task
      vol.tasks = vol.tasks.filter((task) => {
        // returns false if id is null (task was added before id was assigned)
        if (task.id !== null) {
          // returns false if the task.id matches the current id of the current task
          if (task.id !== dataId) {
            // returns true if the task.id isn't null nor does it match the current id
            return true;
          }
          return false;
        }
        return false;
      });
      // if the user is saving then add the current task to volunteer tasks array
      if (isSaving) {
        // Adding task to selected volunteers
        cardID.forEach((volId) => {
          if (vol.id === volId) {
            vol.tasks.push(tempTask);
          }
        });
      }
      // Returning volunteer with corrected list of tasks
      return vol;
    });
    // Setting volunteers and task
    tempAllVolunteers.forEach((volunteer) => {
      SetDataById("volunteers", volunteer, null, setUpdate);
    });
  };
  // HANDLING UPDATE STATE
  if (update === "Done") {
    setTimeout(() => setUpdate("Idle"), 1000);
  }
  //====================================================
  //#endregion

  return (
    <main className="editTasksContainer">
      <TopNav
        history={props.history}
        isEditing={isEditing}
        handleSave={handleSave}
        handleDelete={handleDelete}
        update={update}
        dialogDep={dialogDep}
        setDialogDep={setDialogDep}
      />
      <ScrollContainer
        ignoreElements=".task-volunteer-container, input, textarea"
        className="editFieldContainer"
      >
        <div className="taskTitleContainer">
          <div className="editTaskTitle">
            <span className="cardTitle">Title *</span>
            <input
              type="text"
              required
              ref={taskTitle}
              value={fields.taskTitle}
              maxLength={50}
              onChange={(e) => {
                setFields({ ...fields, taskTitle: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
                clearRequiredAlert(taskTitle);
              }}
              placeholder="A task title..."
            />
          </div>
          <div className="editTaskTitleFR">
            <span className="cardTitle">Title (French) *</span>
            <input
              type="text"
              required
              ref={taskTitleFR}
              value={fields.taskTitleFR}
              maxLength={50}
              onChange={(e) => {
                setFields({ ...fields, taskTitleFR: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
                clearRequiredAlert(taskTitleFR);
              }}
              placeholder="Le titre de la tâche..."
            />
          </div>
        </div>
        <div className="taskLocation">
          <span className="cardTitle">Location</span>
          <input
            type="text"
            value={fields.taskLocation}
            maxLength={50}
            onChange={(e) => {
              setFields({ ...fields, taskLocation: e.target.value });
              setDialogDep({ ...dialogDep, edited: true });
            }}
            placeholder="44 Some St., Somewhere, Canada..."
          />
        </div>
        <div className="taskDescriptionContainer">
          <div className="taskDescription">
            <span className="cardTitle">Description</span>
            <textarea
              type="text"
              value={fields.taskDescription}
              maxLength={125}
              onChange={(e) => {
                setFields({ ...fields, taskDescription: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="A task description..."
            />
          </div>
          <div className="taskDescriptionFR">
            <span className="cardTitle">Description (French)</span>
            <textarea
              type="text"
              value={fields.taskDescriptionFR}
              maxLength={125}
              onChange={(e) => {
                setFields({ ...fields, taskDescriptionFR: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="Une description de la tâche..."
            />
          </div>
        </div>
        <div className="taskNotesContainer">
          <div className="taskNotes">
            <span className="cardTitle">Notes</span>
            <textarea
              type="text"
              value={fields.taskNotes}
              maxLength={250}
              onChange={(e) => {
                setFields({ ...fields, taskNotes: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="Some notes..."
            />
          </div>
          <div className="taskNotesFR">
            <span className="cardTitle">Notes (French)</span>
            <textarea
              type="text"
              value={fields.taskNotesFR}
              maxLength={250}
              onChange={(e) => {
                setFields({ ...fields, taskNotesFR: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="Des notes..."
            />
          </div>
        </div>
        <div className="taskDateContainer">
          <div className="taskStartDate">
            <span className="cardTitle">Start Date *</span>
            <input
              type="date"
              required
              value={GetInputDateFormat(fields.taskStartDate)}
              onChange={(e) => {
                let fixedDate = e.target.valueAsDate;
                fixedDate.setUTCDate(fixedDate.getUTCDate() + 1);
                fixedDate.setUTCHours(0);
                fixedDate.setUTCMinutes(0);
                fixedDate.setUTCSeconds(0);
                setFields({ ...fields, taskStartDate: fixedDate });
                setDialogDep({ ...dialogDep, edited: true });
              }}
            />
          </div>
          <div className="taskEndDate">
            <span className="cardTitle">End Date *</span>
            <input
              type="date"
              required
              value={GetInputDateFormat(fields.taskEndDate)}
              onChange={(e) => {
                let fixedDate = e.target.valueAsDate;
                fixedDate.setUTCDate(fixedDate.getUTCDate() + 1);
                fixedDate.setUTCHours(0);
                fixedDate.setUTCMinutes(0);
                fixedDate.setUTCSeconds(0);
                setFields({ ...fields, taskEndDate: fixedDate });
                setDialogDep({ ...dialogDep, edited: true });
              }}
            />
          </div>
          <div className="taskStartTime">
            <span className="cardTitle">Start Time *</span>
            <input
              type="time"
              required
              value={GetInputTimeFormat(fields.taskStartDate)}
              onChange={(e) => {
                let time = e.target.value.split(":");
                let hours = time[0];
                let minutes = time[1];
                let date = GetDateFormat(fields.taskStartDate);
                date.setHours(hours);
                date.setMinutes(minutes);
                setFields({ ...fields, taskStartDate: date });
                setDialogDep({ ...dialogDep, edited: true });
              }}
            />
          </div>
          <div className="taskEndTime">
            <span className="cardTitle">End Time *</span>
            <input
              type="time"
              required
              value={GetInputTimeFormat(fields.taskEndDate)}
              onChange={(e) => {
                let time = e.target.value.split(":");
                let hours = time[0];
                let minutes = time[1];
                let date = GetDateFormat(fields.taskEndDate);
                date.setHours(hours);
                date.setMinutes(minutes);
                setFields({ ...fields, taskEndDate: date });
                setDialogDep({ ...dialogDep, edited: true });
              }}
            />
          </div>
        </div>
        <div className="taskPeopleContainer" ref={taskVolunteers}>
          <span className="cardTitle">Assigned Volunteers *</span>
          <ScrollContainer className="task-volunteer-container">
            {volunteers &&
              volunteers.map((volunteer) => {
                let id = volunteer.id;
                return (
                  <div
                    id={volunteer.id}
                    key={volunteer.id}
                    className={
                      cardID.includes(volunteer.id)
                        ? "task-volunteer-card selected"
                        : "task-volunteer-card"
                    }
                    onClick={(e) => {
                      handleSelection(e);
                      clearRequiredAlert(taskVolunteers);
                    }}
                  >
                    <img
                      src={
                        volunteer.profile_picture
                          ? volunteer.profile_picture
                          : placeholder
                      }
                      alt="Volunteer's profile"
                    />
                    <span className="cardTitle">{volunteer.full_name}</span>
                    {cardID.includes(id) ? (
                      <MdCheck className="indicatorIcon selected" />
                    ) : (
                      <MdFiberManualRecord className="indicatorIcon" />
                    )}
                  </div>
                );
              })}
          </ScrollContainer>
        </div>
      </ScrollContainer>
    </main>
  );
};

export default EditTask;
