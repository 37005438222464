// FIREBASE
import { fire } from "../firebase.js";
const firestore = fire.firestore();

const GetData = (collection, state) => {
  firestore
    .collection(collection)
    .get()
    .then((querySnapshot) => {
      let tempData = [];
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data());
      });
      state(tempData);
    })
    .catch((err) => {});
};

const GetDataById = (collection, state, id) => {
  firestore
    .collection(collection)
    .get()
    .then((querySnapshot) => {
      let tempData = [];
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data());
      });
      tempData = tempData.filter((data) => data.id == id);
      if (state) state(tempData[0]);
    })
    .catch((err) => {});
};

export { GetData, GetDataById };
