import React, { useState, createRef } from "react";
// REACT ICONS
import { MdClose, MdAddCircle } from "react-icons/md";
// CSS
import "./AddArtistVideo.css";

export const AddArtistVideo = ({ artistVideos, setArtistVideos, setModal }) => {
  const [fields, setFields] = useState({
    title: "",
    titleFR: "",
    url: "",
  });

  const handleAdd = () => {
    if (artistVideos.length < 5) {
      if (fields.title && fields.titleFR && fields.url) {
        // Setting state
        setArtistVideos([
          ...artistVideos,
          { title: { en: fields.title, fr: fields.titleFR }, url: fields.url },
        ]);
        // Resetting fields state
        setFields({
          title: "",
          titleFR: "",
          url: "",
        });
        // Focusing on the first input
        input.current.focus();
      }
    }
  };

  const input = createRef();

  return (
    <div className="modal">
      <div className="modalContainer">
        <div className="modalHeader">
          <span className="cardTitle">Add An Artist Video</span>
          <div className="buttonContainer">
            <button
              className="btnSave primary"
              onClick={(e) => {
                handleAdd();
              }}
            >
              <MdAddCircle className="editIcon" />
              Add
            </button>
            <button
              className="btnClose secondary"
              onClick={(e) => setModal(false)}
            >
              <MdClose className="editIcon" />
              Close
            </button>
          </div>
        </div>
        <div className="modalContent">
          <div className="emName">
            <span className="cardTitle">Title *</span>
            <input
              type="text"
              value={fields.title}
              onChange={(e) => setFields({ ...fields, title: e.target.value })}
              maxLength={50}
              ref={input}
              placeholder="A clever name"
            />
          </div>
          <div className="emPhone">
            <span className="cardTitle">Title (French) *</span>
            <input
              type="text"
              value={fields.titleFR}
              maxLength={50}
              onChange={(e) =>
                setFields({ ...fields, titleFR: e.target.value })
              }
              placeholder="A clever name"
            />
          </div>
          <div className="emEmail">
            <span className="cardTitle">Video URL *</span>
            <input
              type="url"
              value={fields.url}
              onChange={(e) => setFields({ ...fields, url: e.target.value })}
              placeholder="A clever name"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddArtistVideo;
