import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
// HORIZONTAL SCROLLING
import ScrollContainer from 'react-indiana-drag-scroll'
// CCS
import "./Events.css"
// PLACEHOLDERS
import placeholder from '../../img/Placeholder_Mono.png'
// ICONS
import { MdAddCircle, MdDateRange } from "react-icons/md"
// FIREBASE DATA
import { GetData } from '../../data/GetData.js'

function Events(props) {

    const history = useHistory();

    //#region STATES
    //====================================================
    const [events, setEvents] = useState();
    const [festivals, setFestivals] = useState();
    //====================================================
    //#endregion

    //#region FETCH
    //====================================================
    useEffect(() => {
        const states = [setEvents, setFestivals]
        const collections = ['events', 'festival_year']
        collections.forEach((collection, index) => {
            GetData(collection, states[index]);
        })
    }, [])
    //====================================================
    //#endregion

    return (
        <main className="events-container">
            <h1 className="title">Events</h1>
            <ScrollContainer ignoreElements=".events-card-container, .festival-card-container" className="cardContainer">
                {/* GENERAL EVENTS */}
                <div className="general-events-container">
                    <div className="subTitle">
                        General Events<MdAddCircle onClick={(e) => history.push("/edit/event/")} className="addIcon" />
                    </div>
                    <ScrollContainer className="events-card-container">
                        {events && events.map(function (event) {
                            return (
                                <NavLink to={"edit/event/" + event.id} key={event.id} className="events-card">
                                    <div className="events-thumbnail-container">
                                        <img src={event.image ? event.image : placeholder} alt="Event" />
                                    </div>
                                    <div className="events-text-container">
                                        <span className="cardTitle">{event.title.en}</span>
                                        <span className="cardSubTitle">{event.date.toDate().toDateString().substring(3, 15)}</span>
                                    </div>
                                </NavLink>)
                        })}
                    </ScrollContainer>
                </div>
                {/* FESTIVALS */}
                <div className="festivals-container">
                    <div className="subTitle">
                        Festivals<MdAddCircle onClick={(e) => history.push("/edit/festival/")} className="addIcon" />
                    </div>
                    <ScrollContainer className="festival-card-container">
                        {festivals && festivals.map(function (festival) {
                            return (
                                <NavLink to={"edit/festival/" + festival.id} key={festival.id} className="festival-card" style={{
                                    backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), url(${festival.image ? festival.image : placeholder})`, backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat', backgroundPosition: 'center'
                                }}>
                                    <div className="festival-image-container">
                                        <span className="cardTitle">{festival.year}</span>
                                        <div>
                                            <span className="cardSubTitle">{festival.start_date.toDate().toDateString().substring(3, 15)}</span>
                                            <span className="cardSubTitle"><MdDateRange className="cal-icon" />{festival.events.length > 1 ? `${festival.events.length} events` : festival.events.length === 1 ? `${festival.events.length} event` : `Add events`}</span>
                                        </div>
                                    </div>
                                </NavLink>)
                        })}
                    </ScrollContainer>
                </div>
            </ScrollContainer>
        </main>
    )
}

export default Events
