// FIREBASE
import { fire } from "../firebase.js";
import imageCompression from "browser-image-compression";
import { DeleteFile, DeleteFestivalEventFile } from "./DeleteData";
const firestore = fire.firestore();
const storage = fire.storage();

const SetData = (collection, data, state) => {
  let doc = firestore.collection(collection).doc();
  let { id } = doc;
  data.id = id;
  state(id);
  doc
    .set(data)
    .then((docRef) => {})
    .catch((error) => {});
};

const SetDataById = (collection, data, idState, completeState) => {
  completeState("Saving");
  let doc = data.id
    ? firestore.collection(collection).doc(data.id)
    : firestore.collection(collection).doc();
  let { id } = doc;
  data.id = id;
  doc
    .set(data)
    .then(() => {
      if (idState) {
        idState(id);
      }
      completeState("Done");
    })
    .catch((error) => {});
};

const SetVolunteer = (data, idState, completeState) => {
  completeState("Saving");
  fire
    .auth()
    .createUserWithEmailAndPassword(data.email, Date.now().toString())
    .then((userCredential) => {
      let { email } = userCredential.user;
      fire
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {})
        .catch((err) => {});
      let { uid } = userCredential.user;
      let doc = firestore.collection("volunteers").doc(uid);
      data.id = uid;
      doc
        .set(data)
        .then(() => {
          idState(uid);
          completeState("Done");
        })
        .catch((error) => {});
    })
    .catch((err) => {
      let doc = firestore.collection("volunteers").doc(data.id);
      doc
        .set(data)
        .then(() => {
          idState(data.id);
          completeState("Done");
        })
        .catch((error) => {});
    });
};

const SetDataUpload = (
  collection,
  data,
  idState,
  fileProg,
  prog,
  completeState
) => {
  completeState("Saving");

  var compConfig = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const performSet = (payload) => {
    let payloadStr = JSON.stringify(payload);
    if (payloadStr.includes(`"image":{}`)) {
      return;
    }
    doc
      .set(payload)
      .then(() => {
        idState(id);
        completeState("Done");
      })
      .catch((error) => {});
  };

  let doc = data.id
    ? firestore.collection(collection).doc(data.id)
    : firestore.collection(collection).doc();
  let { id } = doc;
  data.id = id;

  let { image } = data;

  if (image.name) {
    // DeleteFile(collection, data.id);
    let storageRef = storage.ref("images/" + image.name);
    storageRef
      .getDownloadURL()
      .then((url) => {
        data.image = url;
        performSet(data);
      })
      .catch((err) => {
        imageCompression(image, compConfig)
          .then((compImage) => {
            let task = storageRef.put(compImage);
            task.on(
              "state_changed",
              function progress(snapshot) {
                if (fileProg)
                  fileProg(
                    Math.round(
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    )
                  );
              },
              function error(err) {},
              function complete() {
                task.snapshot.ref.getDownloadURL().then((url) => {
                  data.image = url;
                  performSet(data);
                });
              }
            );
          })
          .catch((err) => {});
      });
  } else {
    if (!image.includes("firebasestorage")) {
      data.image = "";
    }
    performSet(data);
  }
};

const SetDataUploadFestival = (
  collection,
  data,
  idState,
  fileProg,
  prog,
  completeState
) => {
  completeState("Saving");
  fileProg(null);

  var compConfig = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  let doc = data.id
    ? firestore.collection(collection).doc(data.id)
    : firestore.collection(collection).doc();
  let { id } = doc;
  data.id = id;

  let events = data.events.length > 0 ? data.events : null;

  if (events) {
    events.forEach((event, index) => {
      if (event.image.name) {
        let storageRef = storage.ref("images/" + event.image.name);
        storageRef
          .getDownloadURL()
          .then((url) => {
            data.events[index].image = url.toString();
            if (prog === null) {
              SetDataUpload(
                collection,
                data,
                idState,
                fileProg,
                prog,
                completeState
              );
            }
          })
          .catch((err) => {
            fileProg("Uploading");
            imageCompression(event.image, compConfig)
              .then((compImage) => {
                let task = storageRef.put(compImage);
                task.on(
                  "state_changed",
                  function progress(snapshot) {
                    if (fileProg)
                      fileProg(
                        Math.round(
                          (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100
                        )
                      );
                  },
                  function error(err) {},
                  function complete() {
                    task.snapshot.ref.getDownloadURL().then((url) => {
                      data.events[index].image = url.toString();
                      fileProg(null);
                      SetDataUpload(
                        collection,
                        data,
                        idState,
                        fileProg,
                        prog,
                        completeState
                      );
                    });
                  }
                );
              })
              .catch((err) => {});
          });
      } else {
        if (!event.image.includes("firebasestorage")) {
          data.events[index].image = "";
        }
        if (index + 1 === events.length && prog === null) {
          SetDataUpload(
            collection,
            data,
            idState,
            fileProg,
            prog,
            completeState
          );
        }
      }
    });
  } else {
    SetDataUpload(collection, data, idState, fileProg, prog, completeState);
  }
};

export {
  SetDataById,
  SetData,
  SetDataUpload,
  SetDataUploadFestival,
  SetVolunteer,
};
