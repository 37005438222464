import { useState, useLayoutEffect } from "react";
// REACT ROUTER
import { BrowserRouter as Router } from "react-router-dom";
// FIREBASE
import { fire } from "./firebase.js";
// COMPONENTS
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import ResetPassword from "./screens/ResetPassword";
import Loading from "./screens/Loading";
// CSS
import "./App.css";

function App() {
  //#region STATES
  //====================================================
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [reset, setReset] = useState("");
  //====================================================
  //#endregion

  //#region FIREBASE HANDLING
  //====================================================
  const resetCredentials = () => {
    setUser("");
    setEmail("");
    setPassword("");
  };

  const handleLogin = () => {
    setUser("loading");
    if (email === "" && password === "") {
      setLoginError("Please provide your credentials.");
      resetCredentials();
      return;
    } else if (email && password === "") {
      setLoginError("Please provide a password.");
      resetCredentials();
      return;
    } else if (email === "" && password) {
      setLoginError("Please provide an email.");
      resetCredentials();
      return;
    }

    clearErrors();
    // Get admin email from Firestore
    fire
      .firestore()
      .collection("admin_accounts")
      .where("email", "==", email)
      .get()
      .then((doc) => {
        let adminData = null;
        doc.forEach((admin) => {
          adminData = admin.data();
        });
        // If email entered is from an admin then start authentication
        if (adminData && adminData.email === email) {
          fire
            .auth()
            .signInWithEmailAndPassword(email, password)
            .catch((err) => {
              switch (err.code) {
                case "auth/invalid-email":
                case "auth/user-disabled":
                case "auth/user-not-found":
                  setLoginError(err.message);
                  resetCredentials();
                  break;
                case "auth/wrong-password":
                  setLoginError(err.message);
                  resetCredentials();
                  break;
                case "auth/too-many-requests":
                  setLoginError(err.message);
                  resetCredentials();
                  break;
                default:
                  break;
              }
              // setPasswordError("Too many invalid requests. Please wait")
            });
        } else {
          // Not authorized
          setLoginError("This account does not have administrator privileges.");
          resetCredentials();
        }
      })
      .catch((error) => {
        resetCredentials();
      });
  };

  const clearErrors = () => {
    setLoginError("");
  };

  const clearInput = () => {
    setEmail("");
    setPassword("");
  };

  const handleLogout = () => {
    fire.auth().signOut();
  };

  const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        clearInput();
        setUser(user);
      } else {
        resetCredentials();
      }
    });
  };

  useLayoutEffect(() => {
    setUser("loading");
    authListener();
  }, []);
  //====================================================
  //#endregion

  return (
    <div className="App">
      {/* IF USER DOESN'T EXIST */}
      {user === "loading" ? (
        <Loading />
      ) : // IF USER IS LOADING (LOGIN OR REVISIT)
      user === "" && !reset ? (
        <Login
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
          loginError={loginError}
          setReset={setReset}
        />
      ) : // IF USER IS ON RESET PASSWORD
      reset ? (
        <ResetPassword setReset={setReset} />
      ) : (
        // IF USER IS LOGGED IN
        <Router>
          <Dashboard
            handleLogout={handleLogout}
            style={{ position: "fixed", top: "0", left: "0" }}
          />
        </Router>
      )}
    </div>
  );
}

export default App;
