import React, { useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
// LOADER
import Loader from "react-loader-spinner"
// HORIZONTAL SCROLLING
import ScrollContainer from 'react-indiana-drag-scroll'
// ICONS
import { MdAddCircle, MdEmail, MdPhone, MdAccountCircle } from 'react-icons/md'
// CSS
import './Accounts.css'
// PLACEHOLDERS
import placeholder from '../../../img/Placeholder_Profile_Mono.png'
// FIREBASE DATA
import { GetData } from '../../../data/GetData.js'

const Accounts = props => {

  const history = useHistory();

  const [accounts, setAccounts] = useState();

  //#region FETCH
  //====================================================
  useEffect(() => {
    const states = [setAccounts]
    const collections = ['volunteers']
    collections.forEach((collection, index) => {
      GetData(collection, states[index]);
    })
  }, [])
  //====================================================
  //#endregion

  return (
    <div className='accountContainer'>
      <div className='subTitle'>
        {accounts && accounts.length ? accounts.length : <Loader className="loader" type="Oval" color="hsl(140, 45%, 54%)" height={18} width={18} />} Accounts
        <MdAddCircle onClick={(e) => history.push("/edit/account/")} className='addIcon' />
      </div>
      <ScrollContainer className='cardContainer'>
        {accounts && accounts.map(account => (
          <NavLink to={"/edit/account/" + account.id} key={account.id} className='volunteerCard'>
            <img className='accountAvatar' src={account.profile_picture ? account.profile_picture : placeholder} alt='Volunteer' />
            <div className='accountProfile'>
              <span className='cardCaption'>Name</span>
              <span className='cardTitle'>{account.full_name}</span>
            </div>
            <div className='accountContact'>
              <span className='cardCaption'>Volunteer Contact Info</span>
              <span className='cardSubTitle'><MdEmail className="accountIcon" />{account.email}</span>
              <span className='cardSubTitle'><MdPhone className="accountIcon" />{account.phone_number}</span>
            </div>
            <div className='accountEmContact'>
              <span className='cardCaption'>Emergency Contact Info</span>
              <span className='cardSubTitle'><MdAccountCircle className="accountIcon" />{account.emergency_contacts[0] ? account.emergency_contacts[0].name : ""}</span>
              <span className='cardSubTitle'><MdEmail className="accountIcon" />{account.emergency_contacts[0] ? account.emergency_contacts[0].email : ""}</span>
              <span className='cardSubTitle'><MdPhone className="accountIcon" />{account.emergency_contacts[0] ? account.emergency_contacts[0].phone_number : ""}</span>
            </div>
          </NavLink>
        ))}
      </ScrollContainer>
    </div>
  )
}

export default Accounts
