import React, { useEffect, useState, useRef } from "react";
// HORIZONTAL SCROLL
import ScrollContainer from "react-indiana-drag-scroll";
// CSS
import "./Edit.css";
import "./EditArtist.css";
// PLACEHOLDER
import Placeholder from "../../img/Placeholder_Profile_Mono.png";
// FIREBASE GET/SET/DELETE DATA
import { GetDataById } from "../../data/GetData.js";
import { SetDataUpload } from "../../data/SetData.js";
import { DeleteDataById } from "../../data/DeleteData.js";
// TOP NAV
import TopNav from "./TopNav";
// MODALS
import { AddArtistVideo } from "../../modals/AddArtistVideo";
// ICONS
import { MdClose, MdAddCircle } from "react-icons/md";

export const EditArtist = (props) => {
  //#region  STATES
  //====================================================
  // ID
  const [dataId, setDataId] = useState(
    props.match.params.artistId ? props.match.params.artistId : null
  );
  // IF USER IS EDITING OR CREATING
  const [isEditing, setIsEditing] = useState(dataId ? true : false);
  // FETCHED DATA
  const [artistData, setArtistData] = useState();
  // UPDATE STATE
  const [update, setUpdate] = useState("Idle");
  // UPLOAD PROGRESS
  const [uploadProg, setUploadProg] = useState(null);
  // MODAL CONTROLLER
  const [modal, setModal] = useState(false);
  // FIELDS
  const [artistVideos, setArtistVideos] = useState([]);
  const [fields, setFields] = useState({
    name: "",
    description: "",
    descriptionFR: "",
    image: "",
    facebookUrl: "",
    instagramUrl: "",
    twitterUrl: "",
    youtubeUrl: "",
    spotifyUrl: "",
    websiteUrl: "",
  });
  // DIALOG PROPERTIES
  const [dialogDep, setDialogDep] = useState({
    itemName: "",
    edited: false,
    type: "",
    show: false,
  });
  //====================================================
  //#endregion

  //#region REQUIRED FIELDS
  //====================================================
  // REQUIRED FIELD REFERENCES
  const artistName = useRef();
  const artistImage = useRef();
  // REQUIRED FIELD SETUP
  let requiredRefs = [artistName, artistImage];
  let requiredStates = [fields.name, fields.image];
  const MINIMUM = 1;
  let isSafe = !!(fields.name && fields.image);
  // CLEAR REQUIRED FIELD CLASS
  const clearRequiredAlert = (providedRef) => {
    if (!providedRef) {
      requiredRefs.forEach((ref) => {
        ref.current.classList.remove("requiredAlert");
      });
    } else {
      providedRef.current.classList.remove("requiredAlert");
    }
  };
  // ADD REQUIRED FIELD CLASS
  const warnUser = () => {
    requiredStates.forEach((field, i) => {
      if (!field) requiredRefs[i].current.classList.add("requiredAlert");
      if (Array.isArray(field)) {
        if (field.length < MINIMUM)
          requiredRefs[i].current.classList.add("requiredAlert");
      }
    });
  };
  //====================================================
  //#endregion

  //#region DATA FETCH
  //====================================================
  // GETTING DATA
  useEffect(() => {
    if (dataId) {
      const states = [setArtistData];
      const collections = ["acts"];
      collections.forEach((collection, index) => {
        GetDataById(collection, states[index], dataId);
      });
      props.history.replace("/edit/artist/" + dataId);
      setIsEditing(true);
    }
  }, [update]);
  // SETTING DATA
  useEffect(() => {
    if (artistData) {
      setFields({
        name: artistData.name,
        description: artistData.description.en,
        descriptionFR: artistData.description.fr,
        image: artistData.image,
        facebookUrl: artistData.social_media[0].url,
        instagramUrl: artistData.social_media[1].url,
        twitterUrl: artistData.social_media[2].url,
        youtubeUrl: artistData.social_media[3].url,
        spotifyUrl: artistData.social_media[4].url,
        websiteUrl: artistData.social_media[5].url,
      });
      setArtistVideos(artistData.videos);
      setDialogDep({ ...dialogDep, itemName: artistData.name });
    }
  }, [artistData]);
  //====================================================
  //#endregion

  //#region DATA HANDLING
  //====================================================
  // HANDLING SAVE
  const handleSave = () => {
    if (isSafe) {
      let tempData = {
        id: dataId,
        name: fields.name,
        description: {
          en: fields.description,
          fr: fields.descriptionFR,
        },
        image: fields.image,
        social_media: [
          {
            name: "Facebook",
            url: handleURL(fields.facebookUrl) ? fields.facebookUrl : "",
          },
          {
            name: "Instagram",
            url: handleURL(fields.instagramUrl) ? fields.instagramUrl : "",
          },
          {
            name: "Twitter",
            url: handleURL(fields.twitterUrl) ? fields.twitterUrl : "",
          },
          {
            name: "Youtube",
            url: handleURL(fields.youtubeUrl) ? fields.youtubeUrl : "",
          },
          {
            name: "Spotify",
            url: handleURL(fields.spotifyUrl) ? fields.spotifyUrl : "",
          },
          {
            name: "Website",
            url: handleURL(fields.websiteUrl) ? fields.websiteUrl : "",
          },
        ],
        videos: artistVideos,
      };
      // Choosing either SetDataById() or SetData() depending if the user is editing or not
      SetDataUpload("acts", tempData, setDataId, setUploadProg, uploadProg, setUpdate);
    } else {
      warnUser();
    }
  };
  // HANDLING DELETE
  const handleDelete = () => {
    DeleteDataById("acts", dataId, artistData.image, "artists", props.history);
  };
  // HANDLING VIDEO DELETE
  const handleVideos = (index) => {
    let tempArray = artistVideos;
    tempArray.splice(index, 1);
    setArtistVideos(tempArray);
    setFields({ ...fields, videos: artistVideos });
    setDialogDep({ ...dialogDep, edited: true });
  };
  // HANDLING URL VALIDATION
  const handleURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };
  // HANDLING UPDATE STATE
  if (update === "Done") {
    setTimeout(() => setUpdate("Idle"), 1000);
  }
  //====================================================
  //#endregion

  return (
    <main className="editArtistContainer">
      <TopNav
        history={props.history}
        isEditing={isEditing}
        handleSave={handleSave}
        handleDelete={handleDelete}
        uploadProg={uploadProg}
        update={update}
        dialogDep={dialogDep}
        setDialogDep={setDialogDep}
      />
      <ScrollContainer
        ignoreElements=".attendingEventsContainer, input, textarea"
        className="editFieldContainer"
      >
        <div className="artistImage">
          <span className="cardTitle">Artist Image *</span>
          <input
            type="file"
            required
            ref={artistImage}
            onChange={(e) => {
              setFields({ ...fields, image: e.target.files[0] });
              setDialogDep({ ...dialogDep, edited: true });
              clearRequiredAlert(artistImage);
            }}
          />
        </div>
        <img
          className="artistImagePreview"
          alt="artist profile"
          src={
            fields.image
              ? fields.image.name
                ? URL.createObjectURL(fields.image)
                : fields.image
              : Placeholder
          }
        />
        <div className="artistTitle">
          <span className="cardTitle">Artist Name *</span>
          <input
            type="text"
            required
            ref={artistName}
            value={fields.name}
            maxLength={50}
            onChange={(e) => {
              setFields({ ...fields, name: e.target.value });
              setDialogDep({ ...dialogDep, edited: true });
              clearRequiredAlert(artistName);
            }}
            placeholder="An artist name..."
          />
        </div>
        <div className="artistDescriptions">
          <div className="artistBody">
            <span className="cardTitle">Artist Description</span>
            <textarea
              type="text"
              value={fields.description}
              maxLength={250}
              onChange={(e) => {
                setFields({ ...fields, description: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="An artist description..."
            />
          </div>
          <div className="artistBodyFR">
            <span className="cardTitle">Artist Description (French)</span>
            <textarea
              type="text"
              value={fields.descriptionFR}
              maxLength={250}
              onChange={(e) => {
                setFields({ ...fields, descriptionFR: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="Une description pour l'artiste..."
            />
          </div>
        </div>
        <div className="socialMediaLinks">
          <span className="cardTitle">Social Media Links</span>
          <ScrollContainer className="linksCard">
            <div className="link">
              <span className="cardTitle">Facebook</span>
              <input
                type="text"
                value={fields.facebookUrl}
                onChange={(e) => {
                  setFields({ ...fields, facebookUrl: e.target.value });
                  setDialogDep({ ...dialogDep, edited: true });
                }}
                placeholder="www.facebook.com/someone..."
              />
            </div>
            <div className="link">
              <span className="cardTitle">Instagram</span>
              <input
                type="text"
                value={fields.instagramUrl}
                onChange={(e) => {
                  setFields({ ...fields, instagramUrl: e.target.value });
                  setDialogDep({ ...dialogDep, edited: true });
                }}
                placeholder="www.instagram.com/someone..."
              />
            </div>
            <div className="link">
              <span className="cardTitle">Twitter</span>
              <input
                type="text"
                value={fields.twitterUrl}
                onChange={(e) => {
                  setFields({ ...fields, twitterUrl: e.target.value });
                  setDialogDep({ ...dialogDep, edited: true });
                }}
                placeholder="www.twitter.com/someone..."
              />
            </div>
            <div className="link">
              <span className="cardTitle">Youtube</span>
              <input
                type="text"
                value={fields.youtubeUrl}
                onChange={(e) => {
                  setFields({ ...fields, youtubeUrl: e.target.value });
                  setDialogDep({ ...dialogDep, edited: true });
                }}
                placeholder="www.youtube.com/someone..."
              />
            </div>
            <div className="link">
              <span className="cardTitle">Spotify</span>
              <input
                type="text"
                value={fields.spotifyUrl}
                onChange={(e) => {
                  setFields({ ...fields, spotifyUrl: e.target.value });
                  setDialogDep({ ...dialogDep, edited: true });
                }}
                placeholder="www.spotify.com/someone..."
              />
            </div>
            <div className="link">
              <span className="cardTitle">Website</span>
              <input
                type="text"
                value={fields.websiteUrl}
                onChange={(e) => {
                  setFields({ ...fields, websiteUrl: e.target.value });
                  setDialogDep({ ...dialogDep, edited: true });
                }}
                placeholder="www.personalwebsite.com/someone..."
              />
            </div>
          </ScrollContainer>
        </div>
        <div className="artistVideos">
          <span className="cardTitle">
            Artist Video(s)
            <MdAddCircle onClick={(e) => setModal(true)} className="addIcon" />
          </span>
          <span className="cardCaption">{artistVideos.length}/5</span>
          <ScrollContainer className="artistsVideoContainer">
            {artistVideos &&
              artistVideos.map((video, index) => {
                return (
                  <div className="videoCard" key={index}>
                    <span className="cardTitle">
                      <span className="cardSubTitle">Title: </span>
                      {video.title.en}
                    </span>
                    <span className="cardCaption">
                      <span className="cardSubTitle">Url: </span>
                      {video.url}
                    </span>
                    <MdClose
                      className="deleteIcon"
                      onClick={(e) => handleVideos(index)}
                    />
                  </div>
                );
              })}
          </ScrollContainer>
        </div>
      </ScrollContainer>
      {modal ? (
        <AddArtistVideo
          artistVideos={artistVideos}
          setArtistVideos={setArtistVideos}
          setModal={setModal}
        />
      ) : null}
    </main>
  );
};

export default EditArtist;
