import React, { useState, useEffect, useRef } from "react";
import { ts } from "../../firebase";
// HORIZONTAL SCROLL
import ScrollContainer from "react-indiana-drag-scroll";
// REACT ICONS
import { MdClose, MdAddCircle } from "react-icons/md";
// CSS
import "./Edit.css";
import "./EditEvent.css";
// PLACEHOLDER
import Placeholder from "../../img/Placeholder_Mono.png";
// FIREBASE GET/SET/DELETE DATA
import { GetDataById } from "../../data/GetData.js";
import { SetDataUpload } from "../../data/SetData.js";
import { DeleteDataById } from "../../data/DeleteData.js";
import { GetInputDateFormat } from "../../data/GetInputDateFormat.js";
// TOP NAV
import TopNav from "./TopNav";
// MODALS
import AddLineUp from "../../modals/AddLineUp.js";

export const EditEvent = (props) => {
  //#region STATES
  //====================================================
  // ID
  const [dataId, setDataId] = useState(
    props.match.params.eventId ? props.match.params.eventId : null
  );
  // IS EDITING
  const [isEditing, setIsEditing] = useState(dataId ? true : false);
  // FETCHED DATA
  const [eventData, setEventData] = useState();
  // UPDATE STATE
  const [update, setUpdate] = useState("Idle");
  // UPLOAD PROGRESS
  const [uploadProg, setUploadProg] = useState(null);
  // MODAL CONTROLLER
  const [modal, setModal] = useState(false);
  // FIELDS
  const [eventLineUp, setEventLineUp] = useState([]);
  const [fields, setFields] = useState({
    eventTitle: "",
    eventTitleFR: "",
    eventDesc: "",
    eventDescFR: "",
    date: new Date(),
    location: "",
    cost: "",
    eventbriteLink: "",
    image: "",
  });
  // DIALOG PROPERTIES
  const [dialogDep, setDialogDep] = useState({
    itemName: "",
    edited: false,
    type: "cautionDelete",
    show: false,
  });
  //====================================================
  //#endregion

  //#region REQUIRED FIELDS
  //====================================================
  // REQUIRED FIELD REFERENCES
  const eventTitle = useRef();
  const eventTitleFR = useRef();
  // REQUIRED FIELD SETUP
  let requiredRefs = [eventTitle, eventTitleFR];
  let requiredStates = [fields.eventTitle, fields.eventTitleFR];
  const MINIMUM = 1;
  let isSafe = !!(fields.eventTitle && fields.eventTitleFR);
  // CLEAR REQUIRED FIELD CLASS
  const clearRequiredAlert = (providedRef) => {
    if (!providedRef) {
      requiredRefs.forEach((ref) => {
        ref.current.classList.remove("requiredAlert");
      });
    } else {
      providedRef.current.classList.remove("requiredAlert");
    }
  };
  // ADD REQUIRED FIELD CLASS
  const warnUser = () => {
    requiredStates.forEach((field, i) => {
      if (!field) requiredRefs[i].current.classList.add("requiredAlert");
      if (Array.isArray(field)) {
        if (field.length < MINIMUM)
          requiredRefs[i].current.classList.add("requiredAlert");
      }
    });
  };
  //====================================================
  //#endregion

  //#region DATA FETCH
  //====================================================
  // GETTING DATA
  useEffect(() => {
    if (dataId) {
      const states = [setEventData];
      const collections = ["events"];
      collections.forEach((collection, index) => {
        GetDataById(collection, states[index], dataId);
      });
      props.history.replace("/edit/event/" + dataId);
      setIsEditing(true);
    }
  }, [update]);
  // SETTING DATA
  useEffect(() => {
    if (eventData) {
      setFields({
        eventTitle: eventData.title.en,
        eventTitleFR: eventData.title.fr,
        eventDesc: eventData.description.en,
        eventDescFR: eventData.description.fr,
        date: eventData.date,
        location: eventData.location,
        cost: eventData.cost,
        eventbriteLink: eventData.eventbrite_link,
        image: eventData.image,
      });
      setEventLineUp(eventData.line_up);
      setDialogDep({ ...dialogDep, itemName: eventData.title.en });
    }
  }, [eventData]);
  //====================================================
  //#endregion

  //#region DATA HANDLING
  //====================================================
  // HANDLING SAVE
  const handleSave = () => {
    if (isSafe) {
      let tempData = {
        id: dataId,
        title: {
          en: fields.eventTitle,
          fr: fields.eventTitleFR,
        },
        description: {
          en: fields.eventDesc,
          fr: fields.eventDescFR,
        },
        date: fields.date.seconds ? fields.date : ts.fromDate(fields.date),
        location: fields.location,
        cost: fields.cost,
        eventbrite_link: handleURL(fields.eventbriteLink) ? fields.eventbriteLink : "",
        image: fields.image,
        line_up: eventLineUp,
      };
      SetDataUpload("events", tempData, setDataId, setUploadProg, uploadProg, setUpdate);
    } else {
      warnUser();
    }
  };

  // HANDLING DELETE
  const handleDelete = () => {
    DeleteDataById("events", dataId, eventData.image, "events", props.history);
  };
  // HANDLING LINEUP DELETE
  const handleLineUp = (index) => {
    // REVISIT THIS
    let tempArray = eventLineUp;
    tempArray.splice(index, 1);
    setEventLineUp(tempArray);
    setFields({ ...fields, line_up: eventLineUp });
    setDialogDep({ ...dialogDep, edited: true });
  };
  // HANDLING URL VALIDATION
  const handleURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };
  // HANDLING UPDATE STATE
  if (update === "Done") {
    setTimeout(() => setUpdate("Idle"), 1000);
  }
  //====================================================
  //#endregion

  return (
    <main className="editEventContainer">
      <TopNav
        history={props.history}
        isEditing={isEditing}
        handleSave={handleSave}
        handleDelete={handleDelete}
        uploadProg={uploadProg}
        update={update}
        dialogDep={dialogDep}
        setDialogDep={setDialogDep}
      />
      <ScrollContainer
        ignoreElements=".lineUpContainer, input, textarea"
        className="editFieldContainer"
      >
        <div className="eventImage">
          <span className="cardTitle">Event Image</span>
          <input
            type="file"
            required
            onChange={(e) => {
              setFields({ ...fields, image: e.target.files[0] });
              setDialogDep({ ...dialogDep, edited: true });
            }}
          />
        </div>
        <img
          className="eventImagePreview preview"
          alt="event, workshop, concert"
          src={
            fields.image
              ? fields.image.name
                ? URL.createObjectURL(fields.image)
                : fields.image
              : Placeholder
          }
        />
        <div className="eventTitles">
          <div className="eventTitle">
            <span className="cardTitle">Event Title *</span>
            <input
              type="text"
              required
              ref={eventTitle}
              value={fields.eventTitle}
              maxLength={50}
              onChange={(e) => {
                setFields({ ...fields, eventTitle: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
                clearRequiredAlert(eventTitle);
              }}
              placeholder="An event title..."
            />
          </div>
          <div className="eventTitleFR">
            <span className="cardTitle">Event Title (French) *</span>
            <input
              type="text"
              required
              ref={eventTitleFR}
              value={fields.eventTitleFR}
              maxLength={50}
              onChange={(e) => {
                setFields({ ...fields, eventTitleFR: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
                clearRequiredAlert(eventTitleFR);
              }}
              placeholder="Un titre pour l'événement..."
            />
          </div>
        </div>
        <div className="eventDescriptions">
          <div className="eventBody">
            <span className="cardTitle">Event Description</span>
            <textarea
              type="text"
              value={fields.eventDesc}
              maxLength={250}
              onChange={(e) => {
                setFields({ ...fields, eventDesc: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="An event description..."
            />
          </div>
          <div className="eventBodyFR">
            <span className="cardTitle">Event Description (French)</span>
            <textarea
              type="text"
              value={fields.eventDescFR}
              maxLength={250}
              onChange={(e) => {
                setFields({ ...fields, eventDescFR: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="Une description pour l'événement..."
            />
          </div>
        </div>
        <div className="eventDetails">
          <div className="eventLink">
            <span className="cardTitle">EventBrite Link</span>
            <input
              type="text"
              value={fields.eventbriteLink}
              onChange={(e) => {
                setFields({ ...fields, eventbriteLink: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="www.eventbrite.com/event..."
            />
          </div>
          <div className="ticketPrice">
            <span className="cardTitle">Ticket Price</span>
            <input
              type="number"
              value={fields.cost}
              onChange={(e) => {
                if (e.target.value.length <= 3)
                  setFields({ ...fields, cost: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="$30.00"
            />
          </div>
          <div className="eventLocation">
            <span className="cardTitle">Location</span>
            <input
              type="text"
              value={fields.location}
              maxLength={50}
              onChange={(e) => {
                setFields({ ...fields, location: e.target.value });
                setDialogDep({ ...dialogDep, edited: true });
              }}
              placeholder="44 Some St., Somewhere, Canada..."
            />
          </div>
          <div className="eventDate">
            <span className="cardTitle">Date *</span>
            <input
              type="date"
              value={GetInputDateFormat(fields.date)}
              onChange={(e) => {
                let fixedDate = e.target.valueAsDate;
                fixedDate.setUTCDate(fixedDate.getUTCDate() + 1);
                fixedDate.setUTCHours(0);
                fixedDate.setUTCMinutes(0);
                fixedDate.setUTCSeconds(0);
                setFields({ ...fields, date: fixedDate });
                setDialogDep({ ...dialogDep, edited: true });
              }}
            />
          </div>
        </div>
        <div className="eventLineUp">
          <span className="cardTitle">
            LineUp
            <MdAddCircle onClick={(e) => setModal(true)} className="addIcon" />
          </span>
          <ScrollContainer className="lineUpContainer">
            {eventLineUp &&
              eventLineUp.map((LineUp, index) => {
                return (
                  <div className="lineUpCard" key={index}>
                    <div className="lineUpTime">
                      <span className="cardCaption">{LineUp.start_time}</span>
                    </div>
                    <div className="lineUpBody">
                      <span className="cardTitle">{LineUp.content.en}</span>
                    </div>
                    <div className="lineUpButton">
                      <MdClose
                        onClick={(e) => {
                          handleLineUp(index);
                        }}
                        className="editIcon"
                      />
                    </div>
                  </div>
                );
              })}
          </ScrollContainer>
        </div>
      </ScrollContainer>
      {modal ? (
        <AddLineUp
          eventLineUp={eventLineUp}
          setEventLineUp={setEventLineUp}
          setModal={setModal}
          modalStatus={modal}
          eventDate={fields.date}
        />
      ) : null}
    </main>
  );
};

export default EditEvent;
