import React, { useState, createRef } from "react";
// REACT ICONS
import { MdClose, MdAddCircle } from "react-icons/md";
// CSS
import "./AddEmergencyContact.css";

export const AddPollChoice = ({
  emergencyContacts,
  setEmergencyContacts,
  setModal,
}) => {
  const [fields, setFields] = useState({
    name: "",
    phone_number: "",
    email: "",
  });

  const handleAdd = () => {
    if (
      emergencyContacts.length < 2 &&
      fields.name &&
      fields.phone_number &&
      fields.email
    ) {
      setEmergencyContacts([
        ...emergencyContacts,
        {
          name: fields.name,
          email: fields.email,
          phone_number: fields.phone_number,
        },
      ]);
      setFields({
        name: "",
        phone_number: "",
        email: "",
      });
      input.current.focus();
    }
  };

  const input = createRef();

  return (
    <div className="modal">
      <div className="modalContainer">
        <div className="modalHeader">
          <span className="cardTitle">Add An Emergency Contact</span>
          <div className="buttonContainer">
            <button
              className="btnSave primary"
              onClick={(e) => {
                handleAdd();
              }}
            >
              <MdAddCircle className="editIcon" />
              Add
            </button>
            <button
              className="btnClose secondary"
              onClick={(e) => setModal(false)}
            >
              <MdClose className="editIcon" />
              Close
            </button>
          </div>
        </div>
        <div className="modalContent">
          <div className="emName">
            <span className="cardTitle">EC Full Name *</span>
            <input
              type="text"
              value={fields.name}
              maxLength={100}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
              ref={input}
              placeholder="A clever name"
            />
          </div>
          <div className="emPhone">
            <span className="cardTitle">EC Phone *</span>
            <input
              type="tel"
              value={fields.phone_number}
              maxLength={10}
              onChange={(e) =>
                setFields({ ...fields, phone_number: e.target.value })
              }
              placeholder="A clever name"
            />
          </div>
          <div className="emEmail">
            <span className="cardTitle">EC Email *</span>
            <input
              type="email"
              value={fields.email}
              maxLength={50}
              onChange={(e) => setFields({ ...fields, email: e.target.value })}
              placeholder="A clever name"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPollChoice;
