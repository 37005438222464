import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
// LOADER
import Loader from "react-loader-spinner"
// HORIZONTAL SCROLLING
import ScrollContainer from 'react-indiana-drag-scroll'
// ICONS
import { MdPeople, MdAccessTime, MdAddCircle, MdLocationOn } from 'react-icons/md'
// CSS
import './Tasks.css'
// FIREBASE DATA
import { GetData } from '../../../data/GetData.js'

const Tasks = props => {

  const history = useHistory();

  const [tasks, setTasks] = useState();

  //#region FETCH
  //====================================================
  useEffect(() => {
    const states = [setTasks]
    const collections = ['tasks']
    collections.forEach((collection, index) => {
      GetData(collection, states[index]);
    })
  }, [])
  //====================================================
  //#endregion

  return (
    <div className='taskContainer'>
      <div className='subTitle'>
        {tasks && tasks.length ? tasks.length : <Loader className="loader" type="Oval" color="hsl(140, 45%, 54%)" height={18} width={18} />} Tasks
        <MdAddCircle onClick={(e) => history.push("/edit/task/")} className='addIcon' />
      </div>
      <ScrollContainer className='cardContainer'>
        {tasks && tasks.map(task => {
          // { taskCount++ }
          return (
            <NavLink to={"/edit/task/" + task.id} key={task.id} className='taskCard'>
              <div className='taskName'>
                <span className='cardTitle'>{task.name.en}</span>
              </div>
              {/* THESE WOULD NEED TO BE TRANSLATED FROM DATE FORMAT TO STRING */}
              <div className='taskInfo'>
                <span className='cardSubTitle'>
                  <MdAccessTime className='taskIcon' />
                  {(task.start_date.toDate().toDateString().substring(4, 15) + ' - ' + task.end_date.toDate().toDateString().substring(3, 15))}
                </span>
                <span className='cardSubTitle'>
                  <MdLocationOn className="taskIcon" />
                  {task.location ? task.location : "None"}
                </span>
                <span className='cardSubTitle'>
                  <MdPeople className='taskIcon' />
                  {task.volunteers_assigned.length}
                </span>
              </div>
              <div className='taskDesc'>
                <span className='cardCaption'>Description</span>
                <span className='cardSubTitle'>{task.description.en}</span>
              </div>
            </NavLink>
          )
        })}
      </ScrollContainer>
    </div>
  )
}

export default Tasks
